import React from 'react';
import styled from 'styled-components';
import { Info } from 'etiquette';

const InfoArea = styled.div`
  margin-bottom: 46px;
`;

const LoginInfo = ({children}) => (
  <InfoArea>
    <Info>{children}</Info>
  </InfoArea>
);

export default LoginInfo;