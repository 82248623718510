
const TagIds = [
  "Attitude-smiling",
  "Attitude-notSmiling",
  "Attitude-frowning",
  "Demeanor-superFriendly",
  "Demeanor-friendly",
  "Demeanor-unfriendly",
  "Pace-fast",
  "Pace-ok",
  "Pace-slow",
  "Interactions-adequate",
  "Interactions-tooMany",
  "Answered menu questions-perfectly",
  "Answered menu questions-ok",
  "Answered menu questions-notGood",
  "Food delivery was...-perfectly",
  "Food delivery was...-adequate",
  "Food delivery was...-slow",
  "Food delivery was...-noOpinion",
  "Food temperature was...-perfectly",
  "Food temperature was...-adequate",
  "Food temperature was...-incorrect",
  "Food temperature was...-noOpinion",
  "Your drink service was...-fastFlowing",
  "Your drink service was...-ok",
  "Your drink service was...-alwaysDelayed",
  "Your drink service was...-noOpinion",
];

export default TagIds