const ES = {
    "WELCOME":"¡Bienvenido!",
    "LOGIN_SUBTITLE":"Por favor inserte los datos requeridos.",
    "CASHIER_USER":"Usuario de caja",
    "PASSWORD":"Contraseña",
    "REMEMBERME":"Recuérdame.",
    "LOGIN":"Acceder",
    "LOGIN_ALERT" : "El usuario o contraseña es incorrecto",
    
    "TICKET_MISSING": "Ticket faltante",
    "CHECK_DATA" : "Verifique los datos y comience la encuesta cuando esté listo.",
    "START_SURVEY":"Empezar Encuesta",
    'AMOUNT':'Cantidad',
    'NUM_GUESTS':"Número de asistentes",
    "CONFIRM_NUMBER": "Confirmar número de asistentes (mesero reporta ",
    "MANAGER":'Gerente',
    "OPERATOR":'Asistente del gerente',
    "NUM_TABLE": "# Mesa",
    "NUM_TICKET": "# Ticket",
    "WAITER":'Mesero',

    "GOAL" : "Nuestra meta es la",
    "PERFECTION" : '"Perfección" en Quince.',
    "HOW_DONE" : "¿Cómo nos fue?",

    "END_SURVEY": "Finaliza la encuesta en cualquier momento",
    "THANK_YOU":"Gracias",
    "HAVE_EMAIL": "Tenemos su email y",
    "WILL_CONTACT": "estaremos en contacto.",

    "NOT_RATED": "Actualmente no calificado",
    "RATED_WITH": "Calificado con",
    "STARS": "estrellas",

    "CONTACT_INFO": "Proporcione su información de contacto para recibir nuestras ofertas especiales.",
    "CONTACT_VIA": "Contactar por",
    "PHONE": "Teléfono",
    "EMAIL":"Correo",
    "MOBILE":"Celular",
    "FIRST_NAME":"Nombre",
    "LAST_NAME": "Apellido",
    "WANT_CONTACT": "¿Quiere que le contactemos?",
    "YES": "Si",
    "NO": "No",
    "LIKE_MANAGEMENT": "¿Le gustaría que gerencia le contactara por alguna razón?",
    "WICH_REASON":"¿Cuál es el motivo?",
    "COMPLEMENT_US":"Complementarnos",
    "HAD_PROBLEM": "Un problema",
    "OTHER_REASON": "Otra razón",
    "WHAT_REASON":"¿Cuál es la razón?",

    "RATE_MANAGER":"Calificar al gerente",
    "SELECT_MANAGER":"Seleccione al gerente que hoy revisó la experiencia en su mesa.",

    "RATE":"Califique su experiencia",
    "RATE_CONNECTOR":"con",
    "LOW_RATING1" : "Oh,",
    "LOW_RATING2":"estrellas no es nuestra mejor calificación... ¿Qué pudo haber hecho mejor",
    "LOW_RATING3":"?",
    "LOW_RATING4":"Calificación muy baja... por favor cuéntenos que puede hacer mejor",
    "LOW_RATING5":"la próxima vez.",

    "DEMEANOR":"Comportamiento",
    "VERY_FRIENDLY":"Super Amable",
    "SEMI_FRIENDLY":"Amable",
    "Ok":"Ok",
    "UNFRIENDLY":"Hostil",
    
    "FOLLOW_UP":'Seguimiento',
    "VERY_HELPFUL":'Muy Servicial',
    "NO_HELP":'No Tuve Problemas',
    "NO_HELPFUL":'No Servicial al Necesitarle',
    
    "RATE_HOSTESS":"Califique al Anfitrión",
    "SELECT_HOST":"Seleccione a quien le haya dado la bienvenida en la recepción.",
    "THAT_RATING": "Comentenos más para que ",
    "THAT_RATING_COMPLEMENT":" pueda tener 5 estrellas la próxima vez.",

    "ATTITUDE":"Actitud",

    "ALWAYS_SMILING":"Sonriente",
    "SMILING_ENOUGH":"Ok",
    "FROWNING":"Disgustado",

    "COMMUNICATION":"Comunicación",
    "GREAT":"Genial",
    "OK":"Adecuada",
    "POOR":"Mala",

    "GASTRONOMIC_TITLE": "¿Calificaría con 5 estrellas los alimentos servidos en su mesa?",
    "CONTINUE": "Continuar",
    "TEXT_AREA_TITLE_FOOD": "Algún comentario sobre los alimentos por favor escriba aquí.",
    "TEXT_AREA_TITLE_DRINK": "Algún comentario sobre su bebida por favor escriba aquí.",

    "GASTRONOMIC_TITLE_DRINK": "¿Calificaría con 5 estrellas las bebidas servidas en su mesa?",
    "DRINKS_SUBTITLE": "Califique las bebidas servidas en su mesa",
    "GASTRONOMIC_RATE_ITEMS_DRINK": "Califique solo las bebidas que usted o su mesa experimentaron.",
    "DRINK_ITEMS1": "de",
    "DRINK_ITEMS2": "artículos valorados",
    "DRINK_PLACEHOLDER": "Proporcione comentarios generales sobre bebidas para cualquier artículo.",

    "FOOD_SUBTITLE": "Califique los alimentos servidos en su mesa",
    "GASTRONOMIC_RATE_ITEMS_FOOD": "Califique solo los alimentos que usted o su mesa experimentaron.",
    "FOOD_ITEMS1": "de",
    "FOOD_ITEMS2": "artículos valorados",
    "FOOD_PLACEHOLDER": "Proporcione comentarios generales sobre la comida para cualquier artículo.",
    "BREAKFAST": "Desayuno",

    "OVERALL": "Por favor califique su experiencia general en Quince",
    "WHICH_PERFECT": '¿Qué miembro de nuestro equipo preguntó si "todo estuvo perfecto" hoy?',
    "OTHER": "Otro",
    "M/C": "Gerente / Capitán",
    "SERVER": "Mesero",
    "NOBODY": "Nadie",
    "PERFECT_MANAGER": "Gerente",
    "CAPTAIN": "Capitán",
    "NO_REMEMBER": "No recuerdo",

    "DONT_REMEMBER":"No recuerdo",
    "OPORTUNITY_MET_CHEF":"¿Su mesa tuvo la oportunidad de conocer a un chef de Quince hoy?",

    "RATE_TABLE":"Califique el servicio.",
    "REALLY_STARS":"Comentenos más para que ",
    "REALLY_STARS_COMPLEMENT":" pueda tener 5 estrellas la próxima vez.",
    "SERVICE": ".",

    "PACE": "Velocidad",
    "MOVE_FAST": "Rápido",
    "OK_SPEED":"Buena Velocidad",
    "SNAIL_PACE":"Lento como Caracol",

    "INTERACTIONS":"Interacciones",
    "CONFORTABLE":"Interacciones Adecuadas",
    "TOO_MANY_INTERRUPTIONS":"Demasiadas Interrupciones",

    "ANSWERED_MENU":"Respondió preguntas del menú",

    "PERFECTLY":"Perfectamente",
    "MENU_OK": "Bien",
    "NOT_GOOD":"No Supo",

    "FOOD_WAS":"La entrega de platillos fue ...",
    "PERFECT":"Perfecta",
    "SLOW":"Lenta",
    "NO_OPINION":"Sin Opinión",
    "NO_PROBLEM":"Sin Problema",


    "FOOD_TEMPERATURE":"La temperatura de los platillos fue ...",
    "ADECUATE":"Adecuada",
    "INCORRECT":"Incorrecta",

    "DRINK_WAS":"El servicio de barra fue ...",
    "FAST_FLOWING":"Rápido y contínuo",
    "BARRA_OK": "Ok",
    "ALWAYS_DELAYED":"Siempre Lento",

    "RESERVATIONS":"¿Nuestro equipo de Hosting fue profesional al reservar su mesa?",
    "RESERVATION_NO_OPINION":"Reservé en línea",
    "RESERVATION_OK":"Profesional / Amigable",
    "RESERVATION_PROBLEM":"No Profesional / No Amigable",

    "VERY_HELP_OPTIONS":"Con opciones muy útiles",
    "NO_HELP_OPTIONS":"Sin opciones útiles",

    "HOW_MANY_MINUTES":"¿Cuántos minutos esperó en la recepción?",
    "MORE_HOUR":"+ 1 hora",

    "COURTESY_DRINK":'¿Le ofrecieron una bebida de cortesía mientras esperaba?',
    "BETTER_DRINK":"Esperar es mejor con una copa, ¿no cree?",

    "MUSIC": "Música",
    "MUSIC_TITLE": "Nuestra música es para su comodidad y felicidad.",
    "MUSIC_ADVISE": "Por favor díganos más. ¿El problema con la música fue?",
    "WRONG_GENRE": "Género Incorrecto",
    "TOO_LOUD": "Muy Fuerte",
    "NOT_FAMILIAR": "No Familiar",
    "OTHER_PROBLEM": "Otro problema",

    "ESTIMATE_MINUTES": "¿Cuánto tiempo pasó entre que solicitó su cuenta y la recibió?",

    "LOGOUT": "Cerrar sesión",

    "FINISHED" : "¿Terminó con la encuesta?",
    "YOUR_PERCENT":"Ha completado ",
    "YES_DONE":'Si, he terminado',
    "NO_GOBACK": 'No, volver',

    "THANK_YOUR_TIME": "¡Gracias por su tiempo!",
    "ON_TOUCH": "Tenemos su correo electrónico y nos pondremos en contacto pronto.",

    "LEARN_MORE":"¿Le gustaría aprender más sobre nuestro sistema de encuestas",
    "SURVEY_SYSTEM": "para su negocio?",
    "YES_LEARN":"Si, quiero aprender",

    "THANK_YOU_TIME":"¡Gracias por su tiempo!",
    "ENJOY_VISIT":"Esperamos que haya disfrutado de su visita.",

    "RECEIVED":"Hemos recibido su información de contacto y comercial.",
    "TOUCH_SOON":"Nos veremos pronto.",

    "TO_JOIN":"Para unirse a la \"Comunidad de Quince\", recibir beneficios y oportunidades VIP, proporcione su información a continuación.",
    "NOT_SPAM":"Prometemos no enviarle spam.",
    "Mr":"Sr.",
    "Ms":"Sra.",
    "NotD":"Prefiero no decirlo",

    "YOUR_INFO":"Su información",
    "VIP_PROMO":"También me gustaría unirme a la Comunidad de Quince y recibir promociones VIP.",

    "PROGRESS_0": "Empecemos",
    "PROGRESS_20": "Buen comienzo",
    "PROGRESS_30": "Nos esta ayudando",
    "PROGRESS_40": "Avance constante",
    "PROGRESS_50": "Sigue adelante",
    "PROGRESS_60": "A medio camino",
    "PROGRESS_70": "Va muy bien",
    "PROGRESS_80": "Ya se ve la luz al final del tunel",
    "PROGRESS_90": "Muy cerca",
    "PROGRESS_100": "¡Terminado!... el equipo le da las gracias",

    "REQUIRED":"Campo requerido",
    "REQUIRED_EMAIL_PHONE":"Email o teléfono requerido",

    "NAME_COMPANY":"Nombre de la compañia",
    "TYPE_BUSINESS":"Tipo de negocio",
    "CITY":"Ciudad del negocio",
    "NUMBER_LOCATIONS":"Número de locales",
    "POSITION":"Su puesto",
    "BUSINESS_INFO":"Información del negocio",

    "SEND_INFO":"Enviar",
    "INVALID_NUMBER":"Este no parece ser un número de teléfono válido",

    "All Hosts team":"Todo el equipo de anfitriones",
    "Don't Remember":"No recuerdo",
    "All Managers Team":"Todo el equipo de gerentes",
    "All Manager Team":"Todo el equipo de gerentes",

    "INVALID_EMAIL":"El correo es incorrecto",
    "INVALID_REASON":"Seleccione un motivo",

    "BUSINESS_TITLE": "Por favor compártanos tu información.",

    "NO_SPACE":"No se permiten espacios '  ' ",
    "NO_ARROBA":"Debe incluir el símbolo @, ejemplo: jonhSmith@gmail.com",
    "NO_ARROBA_MORE":"Solo 1 símbolo @ permitido, ejemplo: jonhSmith@gmail.com",
    "NO_SERVICE":"Debe incluir un servicio de correo, por ejemplo: gmail, hotmail, yahoo, etc ...",
    "NO_DOT":"Debe terminar en un dominio, por ejemplo: .com, .mx, .xyz, .co, etc ...",
    "Minutes":"Minutos",

    "Acceptable timing": "Tiempo aceptable",
    "Small delay": "Tardó",
    "Long delay (asked 1x)": "Tardó mucho (pregunté 1x)",
    "Long delay (asked more than 1x)": "Pregunté muchas veces",

    "Table": "Mesa"
}

export default ES;