
const addObjectPath = (obj, is, value) => {
  return is.length > 1 ? addObjectPath(obj.hasOwnProperty(is[0]) ? obj[is[0]] : (is.length===1 ? obj[is[0]]=value : obj[is[0]]={}), is.slice(1), value) : obj[is]=value;
}

export const addValueToObject = (obj, is, value) => { 
  return addObjectPath(obj,is.split('.'), value);
}

const multiPath = (obj,is) => {
  return is.length ? multiPath(obj[is[0]],is.slice(1)) : obj
}

export const getPathValue = (obj,is) => { 
  try{
    return multiPath(obj,is.split('.'));
  } catch(e){
    return null;
  }
}

export function cloneObject(target) {
  const stringify = JSON.stringify(target);
  const result = JSON.parse(stringify);
  return result
}