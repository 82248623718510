import React, { useContext, useState } from 'react';
import { TagBox, CircularCheckboxWhite, BlueInput } from 'etiquette';
import { MobileInput } from 'components/Phone/index';
import { TextArea } from 'pages/Survey/containers/FoodDrinks';
import useI8n from 'i18n/useI18n';
import { getFullPhoneNumber, isValidNumber } from 'components/Phone/utils/text';
import { SurveyContext } from 'providers/survey';
import { Required, Leyend } from '../Style';
import { ValidatorContext } from 'providers/validator';
import { BusinessContext } from 'providers/business';
import isValidEmail from 'pages/Survey/utils/validEmail';
import { getPathValue } from 'utils/objectUtils';
import EmailLeyends from 'pages/Survey/components/EmailLeyends';
import phoneFormat from 'utils/phoneFormat';
import BlueInputMail from 'components/BlueInputMail';


const ContactVia = ({phoneRef, emailRef, checkboxRef}) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);
  const [contactPhone, setState] = useState({ number: '', country: '52' });
  const { errors } = useContext(ValidatorContext);
  const { setBusiness } = useContext(BusinessContext);

  const handlePhoneChange = (country, number) => {
    phoneFormat(number, contactPhone )
    .then((response)=>{
      setState({ country, number: response });
    })
    if (!country || !number) return;
    setSurvey('cx_contact_phone', getFullPhoneNumber(country, number));
    setBusiness('phone', getFullPhoneNumber(country, number));
  };

  const contactVia = {
    phone: false,
    email: false,
  };

  const handleContactVia = (via) => {
    const newVia = { ...contactVia };
    newVia[via] = true;
    setSurvey('contact_via', newVia);
  };

  const phoneHasError = () => {
      if(survey?.contact_via?.phone === false) return false;
      if(errors.includes('contact_phone') && !isValidNumber(contactPhone.country, contactPhone.number)) return true;
      if(errors.includes('contact_phone') && isValidNumber(contactPhone.country, contactPhone.number) && contactPhone.number.trim().length > 0) return false;
      if(!isValidNumber(contactPhone.country, contactPhone.number) && contactPhone.number.trim().length !== 0) return true;
      return false;
  }
  const emailHasError = () => {
      if(survey?.contact_via?.email === false) return false;
      if(errors.includes('contact_email') && !isValidEmail(getPathValue(survey, 'cx_contact_email'))) return true;
      if(errors.includes('contact_email') && isValidEmail(getPathValue(survey, 'cx_contact_email')) && survey?.cx_contact_email?.trim().length > 0) return false;
      if(!isValidEmail(getPathValue(survey, 'cx_contact_email')) && survey?.cx_contact_email?.trim().length !== 0) return true;
      return false;
  }

  return (
    <TagBox text={translate('CONTACT_VIA')} titleAlign="center">
      <CircularCheckboxWhite
        name="contact-via"
        text={translate('PHONE')}
        label="Phone"
        checked={survey?.contact_via?.phone === true}
        onClick={() => handleContactVia('phone')}
        ref={checkboxRef}
      />
      <CircularCheckboxWhite
        name="contact-via"
        text={translate('EMAIL')}
        label="E-mail"
        checked={survey?.contact_via?.email === true}
        onClick={() => handleContactVia('email')}
        margin="0px 10px 0px 50px"
      />
      {survey?.contact_via?.phone === false && survey?.contact_via?.email === false && (
        <Required>
          <div className="center">
            <span>*</span>
            <Leyend>{translate('REQUIRED_EMAIL_PHONE')}</Leyend>
          </div>
        </Required>
      )}

      {survey?.contact_via?.phone === true && (
        <MobileInput
          title={translate('MOBILE')}
          number={contactPhone.number}
          country={contactPhone.country}
          required={true}
          onChangeNumber={(value) => handlePhoneChange(contactPhone.country, value)}
          onChangeCountry={(value) => handlePhoneChange(value, contactPhone.number)}
          autoComplete={'new-password'}
          ref={phoneRef}
          valid={true}
        />
      )}
      {phoneHasError() && (
        <Required>
          <Leyend>{translate('INVALID_NUMBER')}</Leyend>
        </Required>
      )}

      <TextArea>
        {survey?.contact_via?.email === true && (
          <BlueInputMail
            textLabel={translate('EMAIL')}
            value={survey?.cx_contact_email}
            onChange={(e) => {
              setSurvey('cx_contact_email', e.target.value);
              setBusiness('mail', e.target.value);
            }}
            ref={emailRef}
          />
        )}
        {(emailHasError() && survey?.cx_contact_email === '') && (
            <Required>
              <Leyend>{translate('REQUIRED')}</Leyend>
            </Required>
          )}
        {survey?.contact_via.email && survey?.cx_contact_email !== '' && !isValidEmail(survey?.cx_contact_email)  && (
          <Required>
            <Leyend><EmailLeyends string={survey?.cx_contact_email}  /></Leyend>
          </Required>
        )}
      </TextArea>
    </TagBox>
  );
};

export default ContactVia;
