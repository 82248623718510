import React from 'react';
import styled from 'styled-components';
import colors from './CountrySelector/colors.js';
import CountrySelector from './CountrySelector/index.jsx';
import BlueInputCustom from 'components/BlueInputCustom';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => (margin ? margin : '10px 0')};
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
  font-family: 'Montserrat', sans-serif;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 70px auto;
  justify-items: start;
  padding: 0 10px;
  background-color: ${colors.backgroundLightGrey};
  border-radius: 8px;
  align-items: center;
  text-align: start;
`;

export const InputLabel = styled.h2`
  color: ${colors.textMain};
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
  text-align: left;
`;

export const InfoText = styled.p`
  color: ${({ color }) => (color ? color : colors.textMain)};
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  opacity: 0.5;
  margin: ${({ margin }) => (margin ? margin : '')};
  padding: ${({ padding }) => (padding ? padding : '')};
`;

export const MobileInput = React.forwardRef(({ inputName, title, required, number, country, onChangeNumber, onChangeCountry, valid, autoComplete = 'on' }, ref) => {
  return (
    <>
      <Container>
        <InputContainer>
          <CountrySelector
            preferredCountriesCodes={['MX', 'US']}
            value={country}
            onChange={(value) => {
              onChangeCountry(value);
            }}
          />
          <BlueInputCustom type="tel" title={title} value={number} onChange={(e) => onChangeNumber(e.target.value)} ref={ref} />
        </InputContainer>
      </Container>
      {!valid && (
        <InfoText color={colors.cancel} margin="0" padding="0 0 0 16px">
          INVALID_PHONE
        </InfoText>
      )}
    </>
  );
});
