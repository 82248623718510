import React, { useContext } from "react";
import { TagBox, TagButton } from "etiquette";
import CardStyled from "components/Card";
import Food from "./Food";
import useVisible from "pages/Survey/hooks/useVisible";
import { cloneObject } from 'utils/objectUtils';
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import { fiveStars, ceroStars } from "pages/Survey/utils/surveyChop";

const FoodSurvey = ({ handleProgress }) => {
  const { survey, setSurveyBulk } = useContext(SurveyContext);
  const [visible, handle, transition] = useVisible();
  const { translate } = useI8n();

  const handleSetSurveyBulk = (value) => {
    setSurveyBulk({
      "internal_data.products": value,
      "internal_data.products_details": "",
    })
  }

  const handleStars = (value) => {
    const surveyChop = {...survey.internal_data.products};
    const surveyCopy = cloneObject(surveyChop);
    const move = value === 5;

    if ( move ) {
      handle(0);
      handleProgress("food", 4, false, move);
      const result = fiveStars(surveyCopy);
      handleSetSurveyBulk(result); 
    } else {
      handle(value);
      handleProgress("food", 3, false, true, -0.15);
      const result = [];
      handleSetSurveyBulk(result)
    }
    
  };

  return (
    <CardStyled>
      <TagBox
        text={translate("GASTRONOMIC_TITLE")}
        titleAlign='center'
      >
        <TagButton
          text={translate("YES")}
          onClick={ () => handleStars(5)}
          name="foodSelector"
          label="foodYes"
        />
        <TagButton
          text={translate("NO")}
          onClick={ () => handleStars(4)}
          name="foodSelector"
          label="foodNo"
        />
      </TagBox>

      <Food display={visible} fadeout={transition}/>
    </CardStyled>
  );
};

export default FoodSurvey;
