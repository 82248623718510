import { createContext, useEffect, useState } from 'react';
import { getPathValue } from 'utils/objectUtils';

export const ValidatorContext = createContext();

export const ValidatorProvider = ({ children }) => {

    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        process.env.NODE_ENV === "development" && console.info('inputs', inputs);
    }, [inputs]);

    const registerAll = (inputRefs) => {
        const tmp = {};
        inputRefs.forEach(({key, opts, ref})=>{
            tmp[key] = {opts, ref};
        })
        setInputs(tmp);
    }

    const validate = (survey) => {
      let firstFocus = false;
      const errors = [];
      Object.keys(inputs).forEach((key) => {
        const { ref, opts } = inputs[key];
        if (!opts.validate(survey)) {
          if (!firstFocus) ref?.focus();
          errors.push(key);
        }
      });
      setErrors(errors);

      return errors.length === 0;
    };

    return <ValidatorContext.Provider value={{ registerAll, validate, errors }}>{children}</ValidatorContext.Provider>
}
