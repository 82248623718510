import styled from 'styled-components';
import { CustomText } from 'etiquette';

export const Container = styled.div`
    position: relative;
    padding-bottom: 20px;
`;

export const Title = styled(CustomText)`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
`;

export const Section = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;

    span {
        font-size: 16px;
    }
`;

export const Content = styled.div`
    width: 210px;
    border-radius: 10px;
    background: #ededed;

    display: grid;
    grid-template-columns: 30px auto 50px;
    grid-template-rows: 50px;
    column-gap: 10px;

    align-items: center;
    color: ${({ color }) => color || '#000'};
    justify-items: center;
    cursor: pointer;
    margin: 0;

    user-select: none;

    img {
        width: 20px;
        height: 20px;
        padding-left: 15px;
    }
`;

export const Down = styled.div`
    margin: 0 auto;
    width: 0.6em;
    height: 0.4em;
    background-color: #000;
    clip-path: polygon(0 30%, 50% 100%, 100% 30%, 100% 0, 50% 72%, 0 0);
    @media (max-width: 300px) {
        width: 0.4em;
        height: 0.3em;
    }
`;

export const Up = styled(Down)`
    transform: rotate(180deg);
`;

export const List = styled.div`
    background-color: #fff;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
    width: 210px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;

    p {
        margin: 10px 0;
        padding: 10px 0;
        user-select: none;
    }

    p: hover {
        width: 100%;
        background: #ededed;
        color: #388cff;
    }
`;
