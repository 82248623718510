import React from 'react'
import styled from 'styled-components';
import useI8n from 'i18n/useI18n';

const Text = styled.p`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
  font-family: 'Montserrat', sans-serif;
  color: red;
  font-size: 12px;
  margin: 0;
  text-align: center;
`

const EmailLeyends =  ({string=''}) => {
  const { translate } = useI8n();

  const noEspacios = string.indexOf(' ') >= 0;

  const noArroba = new RegExp('^[\\w-\\.]+@([\\w-\\.\\@])*$')

  const noArrobaTwo = new RegExp('^[\\w-\\.]+@([\\w-\\.])*@([\\w-\\.])*$')

  const noServicio = new RegExp('^[\\w-\\.]+@([\\w-\\.])+$')

  const noPunto = new RegExp('^[\\w-\\.]+@([\\w-])+([\\.\\w-])*\\.([\\w-])+$')

  const complete = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]+$')

  if (string === '' || complete.test(string)) return <p></p>

  if (noEspacios) return <Text>{translate("NO_SPACE")}</Text>
  if (!noArroba.test(string)) return <Text>{translate("NO_ARROBA")}</Text>
  if (noArrobaTwo.test(string)) return <Text>{translate("NO_ARROBA_MORE")}</Text>
  
  if (!noServicio.test(string)) return <Text>{translate("NO_SERVICE")}</Text>
  if (!noPunto.test(string)) return <Text>{translate("NO_DOT")}</Text>

}

export default EmailLeyends;
