
export const capitalLetter = (string) => (
  string.charAt(0).toUpperCase() + string.slice(1)
)

export const firstWord = (string) => {
  const re = /([A-Za-z])\w+/g;
  return re.exec(string)[0]
}

export const textTransformCapitalize = (string) => (
  capitalLetter(firstWord(string).toLowerCase())
)

export const findNumber = (string = '') => {
  const re = new RegExp('([0-9/*\\-+|°¬!"#$%&()=?¡¿¨´~}\\]`{\\[^;:_])+');
  return re.test(string);
}
