import React from "react";
import { Input } from "etiquette";
import useI8n from "i18n/useI18n";

const User = ({ value, onChange, alert }) => {
  const { translate } = useI8n();
  return (
    <Input
      title={translate("CASHIER_USER")}
      name="Input"
      type="text"
      value={value}
      onChange={onChange}
      style={{ marginBottom: "20px" }}
      alert={alert}
      textAlert={translate("LOGIN_ALERT")}
    />
  );
};

export default User;
