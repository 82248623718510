import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './containers/Layout.jsx';
import Login from './pages/Login';
import Survey from './pages/Survey';
import Preview from './pages/Preview';
import Gratefulness from 'pages/Gratefulness';
import Business from './pages/Business';
import { DataProvider } from 'providers/data';
import { SurveyProvider } from 'providers/survey';
import { BusinessProvider } from 'providers/business';
import { ValidatorProvider } from 'providers/validator';
import { I8nProvider } from 'i18n/provider';

const App = () => (
  <BrowserRouter>
    <Layout>
      <I8nProvider>
        <DataProvider>
          <SurveyProvider>
            <BusinessProvider>
              <ValidatorProvider>
                <Switch>
                  <Route exact path='/' component={Login} />
                  <Route exact path='/login' component={Login} />
                  <Route exact path='/preview' component={Preview} />
                  <Route exact path='/survey' component={Survey} />
                  <Route exact path='/gratefulness' component={Gratefulness} />
                  <Route exact path='/business' component={Business} />
                </Switch>
              </ValidatorProvider>
            </BusinessProvider>
          </SurveyProvider>
        </DataProvider>
      </I8nProvider>
    </Layout>
  </BrowserRouter>
);

export default App;
