import styled from 'styled-components';

const TwoCols = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  @media (max-width: 425px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
  }

  .col {
    width: 48%;
    @media (max-width: 425px) {
      width: 100%;
    }
  }

  .col2 {
    width: 46%;
    position: relative;
    top: 16px;
    @media (max-width: 1130px) {
      top: 23px;
    }
    @media (max-width: 430px) {
      top: 25px;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;

export default TwoCols;
