import React, { useContext } from 'react'
import { CustomInput } from "etiquette";
import { SurveyContext } from "providers/survey";
import useI8n from "i18n/useI18n";

const Amount = () => {
  const { translate } = useI8n();
  const { survey } = useContext(SurveyContext);

  return (
    <CustomInput
      icon="dollar"
      title={translate("AMOUNT")}
      IconPosition="start"
      placeholder=". . ."
      value={survey?.amount}
    />
  );
};

export default Amount;