import React, { useContext, useState , useEffect} from "react";
import {
  TagBox,
  TagButton,
  Transition,
  TagBoxVertical,
  Title,
} from "etiquette";

import {
  smilingTags,
  friendlyTags,
  speedTags,
  interruptionsTags,
  menuQuestions,
  foodDelivery,
  foodTemperature,
} from "./server_attributes.js";

import {
  SliceDown,
  HorizontalScroll,
  TitleContainer,
} from "pages/Survey/components/SliceDown";

import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import {DataContext} from "providers/data";
import ScrollToID, { horizontalScrollToID } from 'pages/Survey/utils/scrollToID';


const Server = ({display='none', fadeout}) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);
  const {data} = useContext(DataContext);
  const [waiter, setWaiter] = useState(survey?.internal_data?.waiter?.name);


  useEffect(() => {
      if(!data?.people?.waiters) return;

      const waiterItem = data.people.waiters.find( item => item.userId == survey?.internal_data?.waiter?.id);
      const name =  waiterItem ?  waiterItem.name.toLowerCase() : "";
      const capitalizedName = name.charAt(0).toUpperCase()+name.slice(1);

      if(!!waiterItem) setWaiter(capitalizedName);
  }, [])

  const setTag = (section, baseObject, attribute) => {
    const newObject = { ...baseObject };
    newObject[attribute] = true;
    setSurvey(`server_attributes_rating.${section}`, newObject);
  };

  const setSmilingTags = (attribute) => {
    setTag("smilingTags", smilingTags, attribute)
    horizontalScrollToID("server_horizontal","server-demeanor")
  };
  const setFriendlyTags = (attribute) => {
    setTag("friendlyTags", friendlyTags, attribute)
    horizontalScrollToID("server_horizontal","server-pace")
  };
  const setSpeedTags = (attribute) => {
    setTag("speedTags", speedTags, attribute)
    horizontalScrollToID("server_horizontal","server-interactions")
  };
  const setInterruptionsTags = (attribute) => {
    setTag("interruptionsTags", interruptionsTags, attribute)
    horizontalScrollToID("server_horizontal","server-menu")
  };
  const setMenuQuestions = (attribute) => {
    setTag("menuQuestions", menuQuestions, attribute)
    ScrollToID("FOOD_WAS");
  }
  

  const setFoodDelivery = (attribute) => {
    setTag("foodDelivery", foodDelivery, attribute);
    ScrollToID("FOOD_TEMPERATURE")
  };
  const setFoodTemperature = (attribute) => {
    setTag("foodTemperature", foodTemperature, attribute)
    ScrollToID("DRINK_WAS")
  };
  const setDrinkService = (attribute) => {
    setSurvey('drink_service', attribute)
    ScrollToID("scroll-3")
  };

  return (
    <Transition display={display} fadeout={fadeout}>
      <SliceDown>
        <TitleContainer>
          <Title>
            {translate("REALLY_STARS")}
            <b>
              <i> {waiter}</i>
            </b>
            {translate("REALLY_STARS_COMPLEMENT")}
          </Title>
        </TitleContainer>

        <HorizontalScroll items={5} id="server_horizontal">
          <TagBoxVertical text={translate("ATTITUDE")} id="server-atitudet">
            <TagButton
              name="smilingTags"
              label="Attitude-smiling"
              text={translate("ALWAYS_SMILING")}
              onClick={() => setSmilingTags("smiling")}
              margin="8px 30px"
            />
            <TagButton
              name="smilingTags"
              label="Attitude-notSmiling"
              text={translate("SMILING_ENOUGH")}
              onClick={() => setSmilingTags("notSmiling")}
              margin="8px 30px"
            />
            <TagButton
              name="smilingTags"
              label="Attitude-frowning"
              text={translate("FROWNING")}
              onClick={() => setSmilingTags("frowning")}
              margin="8px 30px"
            />
          </TagBoxVertical>
          <TagBoxVertical text={translate("DEMEANOR")} id="server-demeanor">
            <TagButton
              name="friendlyTags"
              label="Demeanor-superFriendly"
              text={translate("VERY_FRIENDLY")}
              onClick={() => setFriendlyTags("superFriendly")}
              margin="8px 30px"
            />
            <TagButton
              name="friendlyTags"
              label="Demeanor-friendly"
              text={translate("SEMI_FRIENDLY")}
              onClick={() => setFriendlyTags("friendly")}
              margin="8px 30px"
            />
            <TagButton
              name="friendlyTags"
              label="Demeanor-unfriendly"
              text={translate("UNFRIENDLY")}
              onClick={() => setFriendlyTags("unfriendly")}
              margin="8px 30px"
            />
          </TagBoxVertical>
          <TagBoxVertical text={translate("PACE")} id="server-pace">
            <TagButton
              name="speedTags"
              label="Pace-fast"
              text={translate("MOVE_FAST")}
              onClick={() => setSpeedTags("fast")}
              margin="8px 30px"
            />
            <TagButton
              name="speedTags"
              label="Pace-ok"
              text={translate("OK_SPEED")}
              onClick={() => setSpeedTags("ok")}
              margin="8px 30px"
            />
            <TagButton
              name="speedTags"
              label="Pace-slow"
              text={translate("SNAIL_PACE")}
              onClick={() => setSpeedTags("slow")}
              margin="8px 30px"
            />
          </TagBoxVertical>
          <TagBoxVertical
            text={translate("INTERACTIONS")}
            id="server-interactions"
          >
            <TagButton
              name="interruptionsTags"
              label="Interactions-adequate"
              text={translate("CONFORTABLE")}
              onClick={() => setInterruptionsTags("adequate")}
              margin="8px 30px"
            />
            <TagButton
              name="interruptionsTags"
              label="Interactions-tooMany"
              text={translate("TOO_MANY_INTERRUPTIONS")}
              onClick={() => setInterruptionsTags("tooMany")}
              margin="8px 30px"
            />
          </TagBoxVertical>
          <TagBoxVertical text={translate("ANSWERED_MENU")} id="server-menu">
            <TagButton
              name="menuQuestions"
              label="Answered menu questions-perfectly"
              text={translate("PERFECTLY")}
              onClick={() => setMenuQuestions("perfectly")}
              margin="8px 30px"
            />
            <TagButton
              name="menuQuestions"
              label="Answered menu questions-ok"
              text={translate("MENU_OK")}
              onClick={() => setMenuQuestions("ok")}
              margin="8px 30px"
            />
            <TagButton
              name="menuQuestions"
              label="Answered menu questions-notGood"
              text={translate("NOT_GOOD")}
              onClick={() => setMenuQuestions("notGood")}
              margin="8px 30px"
            />
          </TagBoxVertical>
        </HorizontalScroll>
        <div id="FOOD_WAS">
          <TagBox text={translate("FOOD_WAS")} margin="0" titleAlign="center">
            <TagButton
              name="foodDelivery"
              label="Food delivery was...-perfectly"
              text={translate("PERFECT")}
              onClick={() => setFoodDelivery("perfectly")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="foodDelivery"
              label="Food delivery was...-adequate"
              text={translate("ADECUATE")}
              onClick={() => setFoodDelivery("adequate")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="foodDelivery"
              label="Food delivery was...-slow"
              text={translate("SLOW")}
              onClick={() => setFoodDelivery("slow")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="foodDelivery"
              label="Food delivery was...-noOpinion"
              text={translate("NO_OPINION")}
              onClick={() => setFoodDelivery("noOpinion")}
              margin="0 16px 20px 0"
            />
          </TagBox>
        </div>
        <div id="FOOD_TEMPERATURE">
          <TagBox
            text={translate("FOOD_TEMPERATURE")}
            margin="0"
            titleAlign="center"
          >
            <TagButton
              name="foodTemperature"
              label="Food temperature was...-perfectly"
              text={translate("PERFECT")}
              onClick={() => setFoodTemperature("perfectly")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="foodTemperature"
              label="Food temperature was...-adequate"
              text={translate("ADECUATE")}
              onClick={() => setFoodTemperature("adequate")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="foodTemperature"
              label="Food temperature was...-incorrect"
              text={translate("INCORRECT")}
              onClick={() => setFoodTemperature("incorrect")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="foodTemperature"
              label="Food temperature was...-noOpinion"
              text={translate("NO_OPINION")}
              onClick={() => setFoodTemperature("noOpinion")}
              margin="0 16px 20px 0"
            />
          </TagBox>
        </div>
        <div id="DRINK_WAS">
          <TagBox text={translate("DRINK_WAS")} margin="0" titleAlign="center">
            <TagButton
              name="drink_service"
              label="Your drink service was...-fastFlowing"
              text={translate("FAST_FLOWING")}
              onClick={() => setDrinkService("fastFlowing")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="drink_service"
              label="Your drink service was...-ok"
              text={translate("BARRA_OK")}
              onClick={() => setDrinkService("ok")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="drink_service"
              label="Your drink service was...-alwaysDelayed"
              text={translate("ALWAYS_DELAYED")}
              onClick={() => setDrinkService("alwaysDelayed")}
              margin="0 16px 20px 0"
            />
            <TagButton
              name="drink_service"
              label="Your drink service was...-noOpinion"
              text={translate("NO_OPINION")}
              onClick={() => setDrinkService("noOpinion")}
              margin="0 16px 20px 0"
            />
          </TagBox>
        </div>
      </SliceDown>
    </Transition>
  );
}

export default Server;