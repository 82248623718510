import styled from 'styled-components';

export const SliceDown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const HorizontalScroll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 0px 30px 0px;
  overflow-x: auto;
  @media (min-width: 625px) {
    justify-content: ${({items})=>(items < 4 && 'center')};
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin: 0px 0px 30px 0px;
  p {
    text-align: center;
  }
`;