import React, {useEffect} from 'react';
import styled from 'styled-components';
import AmountIcon from "resources/img/amount-icon.svg";
import StatusIcon from 'resources/img/pending-icon.svg'
import DescriptionIcon from "resources/img/description-icon.svg";
import CustomerIcon from "resources/img/name-Icon.svg";
import MailIcon from "resources/img/mail-icon.svg";
import MobileIcon from "resources/img/mobile-icon.svg";
import MinusIcon from 'resources/img/minus-icon.svg';
import PlusIcon from 'resources/img/plus-icon.svg';
import CommentIcon from "resources/img/comment-Icon.svg";
import StarIcon from "resources/img/star-icon.svg";
import SquaresIcon from "resources/img/squares-icon.svg";
import LocationIcon from "resources/img/location-icon.svg";
import BigHashtagIcon from "resources/img/bigHashtag-icon.svg";
import PortfolioIcon from "resources/img/portfolio-icon.svg";

const icons = {
  amount: AmountIcon,
  status: StatusIcon,
  description: DescriptionIcon,
  customer: CustomerIcon,
  mail: MailIcon,
  mobile: MobileIcon,
  minus: MinusIcon,
  plus: PlusIcon,
  hashtag: '#',
  dollar: '$',
  comment: CommentIcon,
  star: StarIcon,
  squares: SquaresIcon,
  location: LocationIcon,
  bigHashtag: BigHashtagIcon,
  portfolio: PortfolioIcon,
}

const Main = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  border-radius: 8px;
  background-color: #EDEDED;
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
  font-family: 'Montserrat', sans-serif;
  .main__icon {
    width: 51px;
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .main__section {
    width: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }
  .main__section--label {
    width: 100%;
    height: 14px;
    font-size: 14px;
    color: #388CFF;
    padding-left: 7px;
    margin-top: 6px; 
    box-sizing: border-box;
    position: relative;
    p {
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
      font-family: 'Montserrat', sans-serif;
      margin: 0;
      position: absolute;
      top: 13px;
      transition: top 0.15s cubic-bezier(.17,.04,.03,1), font-size 0.15s cubic-bezier(.17,.04,.03,1);
      pointer-events: none;
      font-weight: 400;
    }
  }
  .main__section--input {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    padding: 7px 0 11px 0; 
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
  }
  input {
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #0F191B;
    border: 0;
    outline: none;
    padding-left: 7px;
    box-sizing: border-box;
    background-color: #EDEDED;
  }
  img {
    width: 28px;
    height: 28px;
  }
`;

const BlueInputCustom = React.forwardRef((props, ref) => {
  const labelID = (Math.random() * (1000 - 1) + 1).toFixed(2);

  useEffect(() => {
    if (props.value !== '') {
      const label = document.getElementById(labelID);
      label.style.top = '0px';
      label.style.fontSize = '12px';
    }
  }, [props.value])

  useEffect(() => {
    const label = document.getElementById(labelID);
    label.style.top = '13px';
    label.style.fontSize = '14px';
  }, [])

  const handleFocus = () => {
    const label = document.getElementById(labelID);
    label.style.top = '0px';
    label.style.fontSize = '12px';
  }
  const handleBlur = (value) => {
    
    const label = document.getElementById(labelID);
    if (value === '') { 
      label.style.top = '13px';
      label.style.fontSize = '14px';
    }
  } 

  return (
    <Main>
      {props.icon && (
        <div className="main__icon">
          <img src={icons[props.icon]} alt="" />
        </div>
      )}
      <section className="main__section">
        <div className="main__section--label">
          <p id={labelID}>{props.title}</p>
        </div>
        <div className="main__section--input">
          <input
            type={props.type}
            value={props.value}
            onChange={props.onChange}
            onFocus={handleFocus}
            onBlur={() => handleBlur(props.value || "")}
            autoComplete="off"
            required
            // autofocus={props.autofocus || false}
            ref={ref}
          />
        </div>
      </section>
    </Main>
  );
})

export default BlueInputCustom;
