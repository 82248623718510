import React, { useContext } from "react";
import {StarBox,SurveyStars} from "etiquette";
import CardStyled from "components/Card";
import useVisible from "pages/Survey/hooks/useVisible";
import Music from "./Music";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import {ResetTags} from 'pages/Survey/utils/resetTags';

const MusicSurvey = ({ handleProgress }) => {
  const [visible, handle] = useVisible();
  const { survey, setSurveyBulk } = useContext(SurveyContext);
  const { translate } = useI8n();

  const handleChange = (value) => {
    const sameValue = value === survey?.music_rating;
    const zeroFive = sameValue || value === 5;
    const move = value === 5 && !sameValue;

    setSurveyBulk({
      "music_rating": sameValue ? 0 : value,
      "music_below_threshold_reason": zeroFive ? "" : survey?.music_below_threshold_reason,
    })
    
    if(zeroFive) {
      handle(0);
      const tagsId = ['wrongGenre', 'tooLoud', 'notFamiliar', 'other'];
      ResetTags(tagsId);
    } else {
      handle(value);
    }

    handleProgress("music", 11, sameValue, move);
  };

  return (
    <CardStyled>
      <StarBox 
        text={translate("MUSIC")}
        titleAlign='center'
      >
        <SurveyStars value={survey?.music_rating} setValue={handleChange} />
      </StarBox>
      <Music display={visible}/>
    </CardStyled>
  );
};
export default MusicSurvey;