import React from "react";
import useI8n from "i18n/useI18n";
import { Box } from "./styles";
import Success from "pages/Gratefulness/components/Success";

const RecivedInfo = ({ name = "" }) => {
  const { translate } = useI8n();
  return (
    <Box>
      <Success
        title={`${translate("THANK_YOUR_TIME")} ${name}!`}
        subtitle={translate("RECEIVED")}
        plus={translate("TOUCH_SOON")}
      />
    </Box>
  );
};

export default RecivedInfo;