import styled from "styled-components";
import {CustomText} from "etiquette";

export const Required = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  color: red;
  span {
    font-size: 21px;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Leyend = styled(CustomText)`
  font-size: 12px;
  color: inherit;
  margin-left: 5px;
`;

export const MailContainer = styled.div`
  width: 100%;
  margin: 30px 0 10px;
  text-align: start;
`;