import styled from 'styled-components';

export const TitleArea = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  p {
    text-align: center;
  }
`;

export const InfoArea = styled.div`
  width: 100%;
  box-sizing: border-box;
  p {
    text-align: center;
  }
`;
  
export const ImgArea = styled.div`
  width: 100%;
  margin: 30px 0px 10px 0px;
  text-transform: lowercase;
`;

export const SpanArea = styled.div`
  width: auto;
  margin: 0px;
  p {
    font-weight: 400;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const IndividualContainer = styled.div`
  width: 20%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5vw;
  @media (max-width: 485px) {
    width: 33%;
  }
  @media (max-width: 320px) {
    width: 50%;
  }
  @media (max-width: 646px) {
    padding-left: 7vw;
  }
  @media (max-width: 495px) {
    padding-left: 8vw;
  }
`;

  