import { useEffect, useState, useContext } from 'react';
import { DataContext } from "providers/data";
import { SurveyContext } from "providers/survey";
import peopleToList from "../utils/peopleToList";

const usePeople = (people, surveySection, withNickname = true) => {
    const { data } = useContext(DataContext);
    const { setSurvey } = useContext(SurveyContext);

    const [selected, setSelected] = useState(0);
    const [options, setOptions] = useState([]);
    const [peopleList, setPeopleList] = useState([]);

    useEffect(() => {
        setOptions(peopleToList(data.people[people]));
        setPeopleList(data.people[people]);
    }, [data]);


    useEffect(() => {
        if (!options || options.length === 0) return;

        if (selected) {
            const peopleSelected = peopleList.find((p) => +p.userId === +selected)

            const value = {
                id: +peopleSelected.userId,
                name: `${peopleSelected.name} ${peopleSelected.lastName ? peopleSelected.lastName : ""}`
            }

            if (withNickname) {
                value.nickname = peopleSelected.nickname;
            }
            setSurvey(surveySection, value);
        }

    }, [selected, options]);

    const handleOptions = (e) => {
        setSelected(e.target.value);
    };

    return [handleOptions, options, selected , setSelected];
}


export default usePeople;