import React, { useContext } from "react";
import { TagBox, TagButton } from "etiquette";
import CardStyled from "components/Card";
import Drinks from "./Drinks";
import useVisible from "../../hooks/useVisible";
import { cloneObject } from 'utils/objectUtils';
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import { fiveStars, ceroStars } from "pages/Survey/utils/surveyChop";

const DrinksSurvey = ({ handleProgress }) => {
  const { survey, setSurveyBulk } = useContext(SurveyContext);
  const [visible, handle, transition] = useVisible();
  const { translate } = useI8n();

  const handleSetSurveyBulk = (value) => {
    setSurveyBulk({
      "internal_data.mixology": value,
      "internal_data.mixology_details": "",
    })
  }

  const handleStars = (value) => {
    const surveyChop = {...survey.internal_data.mixology};
    const surveyCopy = cloneObject(surveyChop);
    const move = value === 5;

    if ( move ) {
      handle(0);
      handleProgress("drinks", 5, false, move);
      const result =  fiveStars(surveyCopy);
      handleSetSurveyBulk(result);
    } else {
      handle(value);
      handleProgress("drinks", 4, false, true, -0.15);
      const result =  [];
      handleSetSurveyBulk(result);
    }

  };

  return (
    <CardStyled>
      <TagBox 
        text={translate("GASTRONOMIC_TITLE_DRINK")} 
        titleAlign="center"
      >
        <TagButton
          text={translate("YES")}
          onClick={() => handleStars(5)}
          name="drinkSelector"
          label="drinkYes"
        />
        <TagButton
          text={translate("NO")}
          onClick={() => handleStars(4)}
          name="drinkSelector"
          label="drinkNo"
        />
      </TagBox>

      <Drinks display={visible} fadeout={transition} />
    </CardStyled>
  );
};

export default DrinksSurvey;
