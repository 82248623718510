import styled from 'styled-components';

const LogoContainer = styled.figure`
    width: box-container;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Img = styled.img` 
  width:${props=>props.width}; 
  height:${props=>props.height}; 
`;

const Logo = ({src='#', alt='', width='auto', height='auto'}) => (
 <LogoContainer>
   <Img src={src} alt={alt} width={width} height={height}/>
 </LogoContainer>
)

export default Logo;