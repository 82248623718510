export default function productsToTree(products) {
    const categories = getUniqueCategories(products)
    const result = {};
    
    categories.forEach(category => {
        result[category] = getProductsOfCategory(category, products)
    })
    return result;
}

function getUniqueCategories(products) {
    const allCategories = products?.map(p => p.family_en)
    return [...new Map(allCategories?.map((item) => [item, item])).values()];
}

function getProductsOfCategory(category, products) {
    return products.filter(p => p.family_en === category)
}