import { TICKET, PRODUCTS, USERS, CATEGORIES, SAVE, REJECTED, BUSINESS, DOTELL_URL } from 'resources/constants/urls'

const get = (url) => fetch(url).then(r => r.json()).catch(e => console.error(e));

const post = async (url, data) => {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });

    return response.json();
}

export const fetchTicket = (ticketnumber) => {
    const url = `${TICKET}${ticketnumber}`;

    return get(url)
}

export const fetchPeople = async () => {
    const [hostess, managers, operators, cashiers, waiters] = await Promise.all([
        get(DOTELL_URL + "hostess.json"),
        get(DOTELL_URL + "managers.json"),
        get(DOTELL_URL + "operators.json"),
        get(DOTELL_URL + "cashiers.json"),
        get(DOTELL_URL + "waiters.json")
    ])

    return { hostess, managers, operators, cashiers, waiters }
}

export const fetchProducts = () => get(PRODUCTS)
export const fetchUsers = () => get(USERS)
export const fetchCategories = () => get(CATEGORIES)

export const sendSurveyFilled = (data) => post(SAVE, data)
export const sendSurveyRejected = (data) => post(REJECTED, data)

// export const sendSurveyFilled = (data) => {
//     console.log(SAVE, data)
//     return Promise.resolve()
// }

// export const sendSurveyRejected  = (data) => {
//     console.log(REJECTED, data)
//     return Promise.resolve()
// }

export const businessContact = (data) => {
    post(BUSINESS, data);
}
