
const EmptyContactForms = {
  contact_cx: false,
  cx_contact_phone: '',
  cx_contact_email: '',
  cx_name: '',
  cx_lastName: '',
  cx_contact_reason: '',
  contact_other_reason_details: '',
  vip_cx_name: '',
  vip_cx_lastName: '',
  vip_cx_email: '',
}
export default EmptyContactForms
