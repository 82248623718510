import styled from 'styled-components';

export const RadioContainer = styled.div`
  width: 100%;
  display:flex;
  justify-content: flex-start;
  @media (max-width: 500px) {
    justify-content: space-between;
    div {
      margin: 0 10px 0 0;
    }
  }
  @media (max-width: 321px) {
    display: block;
  }
`;