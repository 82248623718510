import React from "react";
import styled from "styled-components";
import { SquareCheckbox } from "etiquette";
import useI8n from "i18n/useI18n";

const CheckArea = styled.div`
  margin-bottom: 48px;
`;

const Check = ({ onClick, checked }) => {
  const { translate } = useI8n();
  return (
    <CheckArea>
      <SquareCheckbox
        text={translate("REMEMBERME")}
        onClick={onClick}
        checked={checked}
      />
    </CheckArea>
  );
};

export default Check;
