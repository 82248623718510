import React from 'react';
import styled from 'styled-components';
import { Title } from 'etiquette';

const TitleArea = styled.div`
  margin-bottom: 20px;
`;

const LoginTitle = ({children}) => (
  <TitleArea>
    <Title>{children}</Title>
  </TitleArea>
);

export default LoginTitle;