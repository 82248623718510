import React , {useContext , useEffect} from 'react';
import CardStyled from 'components/Card.jsx';
import {TagBox, TagButton} from 'etiquette';
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const ChefSurvey = ({handleProgress}) => {
  const { translate } = useI8n();
  const { setSurvey } = useContext(SurveyContext);

  useEffect(() => {
    setSurvey('chef_met',"")
  }, [])

  const handleChef = (value) => {
    setSurvey("chef_met", value)
    handleProgress("meet", 10, false, true);
  }

  return (
    <CardStyled>
      <TagBox
        text={translate("OPORTUNITY_MET_CHEF")}
        titleAlign='center'
      >
        <TagButton
          text={translate("YES")}
          onClick={() => handleChef('yesMet')}
          name="meet"
          label="meet"
        />
        <TagButton
          text={translate("NO")}
          onClick={() => handleChef("noMet")}
          name="meet"
          label="meet1"
        />
        <TagButton
          text={translate("DONT_REMEMBER")}
          onClick={() => handleChef("dontRememberMet")}
          name="meet"
          label="meet2"
        />
      </TagBox>
    </CardStyled>
  );
};

export default ChefSurvey;