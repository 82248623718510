import React, { useContext, useEffect, useState } from "react";
import { PlusRestGrid } from "etiquette";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import { DataContext } from "providers/data";

const Guests = () => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);
  const { data } = useContext(DataContext);

  const [value, setValue] = useState();

  const leyend = !!data?.ticket?.data
    ? `${translate("CONFIRM_NUMBER")} ${data.ticket.data[0].Comensales})`
    : "";

  useEffect(() => {
    setValue(survey.guests);
  }, [survey?.guests]);


  const add = () => {
    const addGuests = survey?.guests + 1;
    setSurvey("guests", addGuests);
  };

  const substract = () => {
    const substractGuests = survey?.guests - 1;
    setSurvey("guests", substractGuests);
  };

  return (
    <PlusRestGrid
      title={translate("NUM_GUESTS")}
      leyend={leyend}
      value={value}
      add={add}
      substract={substract}
    />
  );
};

export default Guests;
