export const helpfulness = {
    helpful:false,
    noHelpNeeded:false,
    notHelpful:false
};

export const friendlyTags = {
    superFriendly: false,
    friendly: false,
    unfriendly: false,
};

export const tagManagerList = ["demeanor-veryfriendly","demeanor-friendly","demeanor-unfriendly","Follow up-helpful","Follow up-noHelpNeeded","Follow up-notHelpful"];