import React, { useContext, useState } from "react";
import { TagBox, TagButton } from "etiquette";
import CardStyled from "components/Card.jsx";
import Form from "./Form";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const Contact = ({ handleProgress }) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);

  useState(() => {
    setSurvey("contact_cx", false);
  }, []);

  const handleClickYes = (value) => {
    setSurvey("contact_cx", value);
    handleProgress("contact", 11, false, true);
  };

  return (
    <>
      <CardStyled>
        <TagBox
          text={translate("LIKE_MANAGEMENT")}
          titleAlign='center'
        >
          <TagButton
            text={translate("YES")}
            onClick={ () => handleClickYes(true)}
            name="contact"
            label="contact"
          />
          <TagButton
            text={translate("NO")}
            onClick={ () => handleClickYes(false)}
            name="contact"
            label="contact1"
          />
        </TagBox>
        {survey.contact_cx &&
          <Form fadeout={true} />
        }
      </CardStyled>
    </>
  );
};
export default Contact;
