import React, { useContext, useEffect, useState } from 'react';
import CardStyled from 'components/Card';
import { SurveyContext } from 'providers/survey';
import useI8n from 'i18n/useI18n';
import styled from 'styled-components';
import { TagBox, TagButton } from 'etiquette';

const Container = styled.div`
    position: relative;
    padding-bottom: 20px;
`;

export const EstimateSurvey = ({ handleProgress }) => {
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState('Select');

    const time = [
        { label: 'Acceptable timing', value: '_05_minutesCheckWait' },
        { label: 'Small delay', value: '_510_minutesCheckWait' },
        { label: 'Long delay (asked 1x)', value: '_1020_minutesCheckWait' },
        { label: 'Long delay (asked more than 1x)', value: '_2030_minutesCheckWait' },
    ];

    useEffect(() => {
        setSurvey('cx_check_wait_time', '');
    }, []);

    const { translate } = useI8n();
    const { setSurvey } = useContext(SurveyContext);

    const handleClick = (time) => {
        setSelected(time.label);
        setSurvey('cx_check_wait_time', time.value);
        handleProgress('check', 3, false, true);
        setVisible(!visible);
    };

    return (
        <>
            <CardStyled>
                <Container>
                    <TagBox text={translate('ESTIMATE_MINUTES')} titleAlign="center">
                        {time.map((item) => (
                            <TagButton
                                text={translate(item.label)}
                                onClick={() => handleClick(item)}
                                name="wait"
                                label={item.label}
                                margin="0 16px 20px 0"
                            />
                        ))}
                    </TagBox>
                </Container>
            </CardStyled>
        </>
    );
};

export default EstimateSurvey;
