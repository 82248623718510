
const format = (number, state) => {
  return new Promise ((resolve) => {
    let newNumber = number;

    if (number?.length < state?.number?.length) {
      newNumber = number
    } else {
      if (number[0] !== "1") {
        if (number.length >= 2 && number.length <= 6) {
          if (number[2] === '-') {
            newNumber = number;
          }else {
            newNumber = number.slice(0,2) + "-" + number.slice(2);
          }
        }
        if (number.length >= 7) {
          if (number[7] === '-') {
            newNumber = number;
          }else {
            newNumber = number.slice(0,7) + "-" + number.slice(7);
          }
        }
      } else {
        if (number.length >= 3 && number.length <= 7) {
          if (number[3] === '-') {
            newNumber = number;
          }else {
            newNumber = number.slice(0,3) + "-" + number.slice(3);
          }
        }
        if (number.length >= 8) {
          if (number[8] === '-') {
            newNumber = number;
          }else {
            newNumber = number.slice(0,8) + "-" + number.slice(8);
          }
        }
      }
    }
    resolve(newNumber)
  })
}

export default format;