import styled from 'styled-components';
import BG from 'resources/img/bg-icons.svg';

const Img = styled.img`
  width: 213px;
  height: 108px;
  position: absolute;
  bottom: 1%;
  right: 2%;

  @media (max-height: 825px) {
    padding-bottom: 10px;
    bottom: -15%;
  }
  @media (max-height: 737px) {
    bottom: -28%;
  }
  @media (max-height: 668px) {
    bottom: -41%;
  }
  @media (max-height: 570px) {
    bottom: -65%;
  }
  @media (max-height: 481px) {
    bottom: -96%;
  }
`;

const Figure = () => (
  <Img src={BG} alt="" />
);

export default Figure;

