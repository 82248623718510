import React, { useContext } from "react";
import CardStyled from "components/Card.jsx";
import { TagBox, TagButton } from "etiquette";
import { SurveyContext } from "providers/survey";
import useI8n from "i18n/useI18n";


const ReservationSurvey = ({ handleProgress }) => {
  const { setSurvey } = useContext(SurveyContext);
  const { translate } = useI8n();

  const baseReservation = {
    helpful: false,
    noProblem: false,
    notHelpful: false,
    noOpinion: false,
  };

  const handleChange = (attribute) => {
    const newReservation = {...baseReservation};
    newReservation[attribute] = true;

    setSurvey("hosting_attributes_rating.reservations", newReservation);

    handleProgress("reservations", 7, false, true);
  };

  return (
    <CardStyled>
      <TagBox
        text={translate("RESERVATIONS")}
        titleAlign='center'
      >
        <TagButton
          text={translate("RESERVATION_NO_OPINION")}
          onClick={() => handleChange("noOpinion")}
          name="reservation"
          label="reservation"
        />
        <TagButton
          text={translate("RESERVATION_OK")}
          onClick={() => handleChange("helpful")}
          name="reservation"
          label="reservation1"
        />
        <TagButton
          text={translate("RESERVATION_PROBLEM")}
          onClick={() => handleChange("notHelpful")}
          name="reservation"
          label="reservation3"
        />
      </TagBox>
    </CardStyled>
  );
};

export default ReservationSurvey;
