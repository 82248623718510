export const smilingTags = {
  smiling: false,
  notSmiling: false,
  frowning: false,
};

export const friendlyTags = {
  superFriendly: false,
  friendly: false,
  unfriendly: false,
};

export const speedTags = {
  fast: false,
  ok: false,
  slow: false,
};

export const interruptionsTags = {
  adequate: false,
  tooMany: false,
};

export const menuQuestions = {
  perfectly: false,
  ok: false,
  notGood: false,
};

export const foodDelivery = {
  perfectly: false,
  adequate: false,
  slow: false,
  noOpinion: false,
};

export const foodTemperature = {
  perfectly: false,
  adequate: false,
  incorrect: false,
  noOpinion: false,
};


const server_attributes = {
  smilingTags: { ...smilingTags },
  friendlyTags: { ...friendlyTags },
  speedTags: { ...speedTags },
  interruptionsTags: { ...interruptionsTags },
  menuQuestions: { ...menuQuestions },
  foodDelivery: { ...foodDelivery },
  foodTemperature: { ...foodTemperature }
}

export default server_attributes;