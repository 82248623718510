import styled from 'styled-components';
import { Title } from 'etiquette';
import useI8n from "i18n/useI18n";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const First = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const Second= styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
const Third = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const Goal = () => {
  const { translate } = useI8n();
  return (
    <Container>
      <First>
        <Title>{translate('GOAL')}</Title>
      </First>

      <Second>
        <Title style={{ fontWeight: "600", fontSize: "36px" }}>
        {translate('PERFECTION')}
        </Title>
      </Second>

      <Third>
        <Title>{translate('HOW_DONE')}</Title>
      </Third>
    </Container>
  );
};

export default Goal;