import { createContext, useEffect, useState, useContext } from 'react';
import { addValueToObject, cloneObject } from 'utils/objectUtils';
import { sendSurveyFilled, sendSurveyRejected } from 'resources/api'
import basicSurvey from './basicSurvey';
import filledSurvey from './filledSurvey';
import rejectedSurvey from './rejectedSurvey'
import { DataContext } from "providers/data";
import cuid from 'cuid';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {

    const { resetTicket } = useContext(DataContext);

    const [fingerprint, setFingerprint] = useState(cuid())

    const [survey, setData] = useState({
        survey_config: { ...basicSurvey.survey_config }
    });

    useEffect(() => {
        process.env.NODE_ENV === "development" && console.info('survey', survey);
    }, [survey]);

    const setSurvey = (key, value) => {
        process.env.NODE_ENV === "development" && console.info(key, value);
        const data = { ...survey };
        addValueToObject(data, key, value);
        setData(data);
    }

    const setSurveyBulk = (items) => {
        const data = { ...survey };
        for (const key in items) {
            addValueToObject(data, key, items[key])
        }

        setData(data)
    }

    const sendSurvey = (status, postUpdate = {}) => {
        processSurvey(status)(postUpdate).catch((e) => console.error("ERROR SENDING SURVEY", e))
    };

    function processSurvey(status) {
        switch (status) {
            case 'started': return processStarted;
            case 'rejected': return processRejected;
            case 'filled': return processFilled;

            default: return processStarted;
        }
    }

    function processStarted() {
        const basic = cloneObject(basicSurvey)

        const payload = {
            ...survey,
            ...basic
        };
        payload.survey_config.fingerprint = fingerprint;

        const filled = cloneObject(filledSurvey)
        setData({ ...survey, ...filled, internal_data: survey.internal_data });

        return sendSurveyFilled(payload)
    }

    function processFilled(postUpdate) {
        const payload = { ...survey }

        payload.survey_config.fingerprint = fingerprint;
        payload.survey_config.status = 'received'

        for (const key in postUpdate) {
            addValueToObject(payload, key, postUpdate[key])
        }

        resetSurvey();
        clean(payload)
        return sendSurveyFilled(payload)
    }

    function clean(payload) {
        removeItemsWithZero(payload.internal_data.products);
        removeItemsWithZero(payload.internal_data.mixology)
    }

    function removeItemsWithZero(list) {
        if (list) {
            for (const key in list) {
                if (Object.hasOwnProperty.call(list, key)) {
                    console.log(key)
                    list[key] = list[key].filter(item => item.rating > 0);
                }
            }
        }
    }

    function processRejected() {
        const rejected = cloneObject(rejectedSurvey);
        const payload = {
            ...rejected,
            internal_data: survey.internal_data
        }

        payload.survey_config.fingerprint = fingerprint;

        resetSurvey();
        return sendSurveyRejected(payload)
    }

    function resetSurvey() {
        const basic = cloneObject(basicSurvey);
        setData({
            survey_config: {
                ...basic.survey_config,
                lang: survey.lang,
            },
            internal_data: {}
        })

        setFingerprint(cuid())
        resetTicket();
    }

    return <SurveyContext.Provider value={{ survey, setSurvey, setSurveyBulk, sendSurvey, resetSurvey }}>{children}</SurveyContext.Provider>
}
