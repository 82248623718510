import {useContext} from 'react';
import {
  TagButton,
  Transition,
  TagBoxVertical,
  Title,
} from "etiquette";
import {
  helpfulness, 
  friendlyTags
} from './manager_attributes_rating';
import {
  SliceDown,
  HorizontalScroll,
  TitleContainer,
} from "pages/Survey/components/SliceDown";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import ScrollToID, { horizontalScrollToID } from 'pages/Survey/utils/scrollToID';


const Manager = ({ display = "none", onClick, fadeout }) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);

  const setTag = (section, baseObject, attribute) => {
    const newObject = { ...baseObject };
    newObject[attribute] = true;
    setSurvey(`manager_attributes_rating.${section}`, newObject);
  };

  const setFriendlyTags = (attribute) => {
    setTag("friendlyTags", friendlyTags, attribute)
    horizontalScrollToID("manager_horizontal","manager-follow")
  };

  const setHelpfulness = (attribute) => {
    setTag("helpfulness", helpfulness, attribute);
    ScrollToID("scroll-10");
  };

  const currentId = survey?.manager_selected?.idManager
  const currentName = survey?.manager_selected?.nameManager.toLowerCase();

  const managerSelected = currentId > 0;
  
  const managerName = managerSelected  ? currentName : ''

  const starts = survey?.manager_basic_rating?.stars >= 4;

  return (
    <Transition
      display={display}
      fadeout={fadeout}
    >
      <SliceDown>
        <TitleContainer>
          <Title >
            {starts 
              ? <>
                {translate("LOW_RATING1")} {survey?.manager_basic_rating?.stars} {translate("LOW_RATING2")}<b><i style={{textTransform: 'capitalize'}}> {managerName}</i></b> {translate("LOW_RATING3")}
                </>
              : <>
                {translate("LOW_RATING4")} <b><i style={{textTransform: 'capitalize'}}> {managerName}</i></b> {translate("LOW_RATING5")}
                </>
            }
          </Title>
        </TitleContainer>
        <HorizontalScroll items={2} id="manager_horizontal">
          <TagBoxVertical text={translate("ATTITUDE")} id="manager-demeanor">
            <TagButton
              name="demeanor"
              label="demeanor-veryfriendly"
              text={translate("VERY_FRIENDLY")}
              onClick={() => setFriendlyTags("superFriendly")}
              margin='8px 30px'
            />
            <TagButton
              name="demeanor"
              label="demeanor-friendly"
              text={translate("Ok")}
              onClick={() => setFriendlyTags("friendly")}
              margin='8px 30px'
            />
            <TagButton
              name="demeanor"
              label="demeanor-unfriendly"
              text={translate("UNFRIENDLY")}
              onClick={() => setFriendlyTags("unfriendly")}
              margin='8px 30px'
            />
          </TagBoxVertical>
          <TagBoxVertical text={translate("DEMEANOR")} id="manager-follow">
            <TagButton
              name="Follow up"
              label="Follow up-helpful"
              text={translate("VERY_HELPFUL")}
              onClick={() => setHelpfulness("helpful")}
              margin='8px 30px'
            />
            <TagButton
              name="Follow up"
              label="Follow up-noHelpNeeded"
              text={translate("NO_HELP")}
              onClick={() => setHelpfulness("noHelpNeeded")}
              margin='8px 30px'
            />
            <TagButton
              name="Follow up"
              label="Follow up-notHelpful"
              text={translate("NO_HELPFUL")}
              onClick={() => setHelpfulness("notHelpful")}
              margin='8px 30px'
            />
          </TagBoxVertical>
        </HorizontalScroll>
      </SliceDown>
    </Transition>
  );
};

export default Manager;
