import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Figure from "./components/Figure.jsx";
import Container from "containers/Container.jsx";
import InternalArea from "containers/InternalArea.jsx";
import Title from "./components/LoginTitle.jsx";
import Info from "./components/LoginInfo.jsx";
import User from "./components/User.jsx";
import Password from "./components/Password.jsx";
import Rememberme from "./components/Rememberme.jsx";
import Submit from "./components/Submit.jsx";
import { Card, Loading } from "etiquette";
import useI8n from "i18n/useI18n";

import { DataContext } from "providers/data";
import { SurveyContext } from "providers/survey";
import Navbar from "../../components/Navbar";

const Login = () => {
  const { loadInfo, data } = useContext(DataContext);
  const { setSurvey } = useContext(SurveyContext);
  const history = useHistory();

  const { translate } = useI8n();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [alerta, setAlerta] = useState(false);

  useEffect(() => {
    loadInfo().finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!data.people?.cashiers) {
      return;
    }

    const cashieruser = localStorage.getItem("cashieruser");
    if (cashieruser) {
      const { user, password } = JSON.parse(cashieruser);
      login(user, password) && history.push("preview");
    }
  }, [data]);

  function login(user, password) {
    const cashiers = data.people.cashiers;
    const cashier = cashiers.find(
      (item) => item.user.trim().toLowerCase() === user.trim().toLowerCase() && item.psw === password
    );

    !!cashier && setSurvey("internal_data.cashier_name", user);

    return !!cashier;
  }

  const handleLogin = () => {
    if (login(user, password)) {
      rememberLogin();
      history.push("preview");
    } else {
      setAlerta(true)
    }
  };

  const rememberLogin = () => {
    if (rememberMe) {
      const cashieruser = { user, password };
      localStorage.setItem("cashieruser", JSON.stringify(cashieruser));
    }
  };

  const handleRememberMe = () => {
    const newValue = !rememberMe;
    setRememberMe(newValue);
  };

  useEffect(() => {
    user.length === 0 && setAlerta(false)
  },[user])

  return (
    <>
      <Navbar />
      <Container>
        <Card>
          <InternalArea>
            <Title>{translate("WELCOME")}</Title>
            <Info>{translate("LOGIN_SUBTITLE")}</Info>
            <User 
              value={user} 
              onChange={(e) => setUser(e.target.value)} 
              alert={alerta}
            />
            <Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Rememberme onClick={handleRememberMe} checked={rememberMe} />

            {isLoading ? (
              <Loading color="#388CFF" margin="0px auto 20px" />
            ) : (
              <Submit onClick={handleLogin} />
            )}
          </InternalArea>
        </Card>
      </Container>
      <Figure />
    </>
  );
};

export default Login;
