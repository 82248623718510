import styled from "styled-components";
import { CustomText, Button, BareBox } from "etiquette";


export const Box = styled(BareBox)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  z-index: 30;
`;

export const Subtitle = styled(CustomText)`
  text-align: center;
  font-size: min(26px, 5vw);
  line-height: 1.2;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 60%;
  margin: 0 auto;

  @media (max-width: 768px) {
    justify-content: space-evenly;
    height: 100%;
  }
`;

export const TextContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 8px;
`;

export const CustomButton = styled(Button)`
  margin: 15px 0 20px;
  width: 40%;

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 375px) {
    margin: 8px 0 6px;
  }
`;
