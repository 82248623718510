//Is the info loaded from APIs
// This data is used to make the survey

// Is the data to send as a completed survey

import { createContext, useState } from 'react';
import { fetchProducts, fetchUsers, fetchCategories, fetchPeople, fetchTicket } from 'resources/api'
import testticket from './testticket'

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        products: [],
        categories: [],
        users: [],
        people: {},
        ticket: {}
    });

    const loadInfo = async () => {
        const [productsList, users, categories, people] = await Promise.all([
            fetchProducts(),
            fetchUsers(),
            fetchCategories(),
            fetchPeople()
        ])

        const products = listToHash("ItemId", productsList)

        setData({
            ...data,
            products,
            users,
            categories,
            people
        })
    }

    function listToHash(id, list) {
        const result = Object.create(null);

        list.forEach(item => result[item[id]] = item);

        return result
    }

    const loadTicket = async (ticketnumber) => {
        if (!ticketnumber) return;

        return fetchTicket(ticketnumber).then((ticket) => {
            if (ticketnumber == "1111") {
                ticket.data = testticket
            }
            if (ticket.data.length === 0) {
                throw new Error(`Ticket not found ${ticketnumber}`)
            }
            setData({ ...data, ticket })
        })
    }

    const resetTicket = () => {
        const newData = { ...data }
        newData.ticket = {};
        setData(newData)
    }

    return <DataContext.Provider value={{ data, loadInfo, loadTicket, resetTicket }}>{children}</DataContext.Provider>
}
