import React, { useContext, useState } from "react";
import {
  SubtitleArea,
  InfoArea,
  ColumnsArea,
  RateContainer,
  TextArea,
} from "pages/Survey/containers/FoodDrinks";
import {
  Transition,
  Subtitle,
  Info,
  RateItemContainer,
  Hr,
  BlueTextArea,
} from "etiquette";
import { SliceDown } from "pages/Survey/components/SliceDown";
import ProductList from "pages/Survey/components/ProductList";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const Item = ({ display = "none", fadeout }) => {
  const { translate } = useI8n();
  const [rated, setRated] = useState(0);
  const [total, setTotal] = useState(0);
  const { survey, setSurvey } = useContext(SurveyContext);

  return (
      <Transition
        display={display}
        fadeout={fadeout}
      >
        <SliceDown>
          <SubtitleArea>
            <Subtitle>{translate("DRINKS_SUBTITLE")}</Subtitle>
          </SubtitleArea>
          <InfoArea>
            <Info>{translate("GASTRONOMIC_RATE_ITEMS_DRINK")}</Info>
          </InfoArea>
          <ColumnsArea>
            <p>{`${rated} ${translate('DRINK_ITEMS1')} ${total} ${translate('DRINK_ITEMS2')}`}</p> 
          </ColumnsArea>
          <RateContainer>
            <RateItemContainer id='drink_horizontal'>
              <ProductList
                setRated={setRated}
                setTotal={setTotal}
                propOnSurvey="internal_data.mixology"
                categoryList="drink"
                containerID='drink_horizontal'
              />
            </RateItemContainer>
          </RateContainer>
          <Hr />
          <TextArea>
            <BlueTextArea
              icon="comment"
              id="DrinkTextArea"
              name="Text area input"
              value={survey?.internal_data?.mixology_details}
              onChange={(e) => setSurvey('internal_data.mixology_details',e.target.value)}
              maxlength="200"
              cols="40"
              rows="3"
              title={translate("TEXT_AREA_TITLE_DRINK")}
            />
          </TextArea>
        </SliceDown>
      </Transition>
  );
};

export default Item;
