import React, { useContext } from "react";
import { TagBox, TagButton, Title, Transition } from "etiquette";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import { SliceDown } from "pages/Survey/components/SliceDown";
import ScrollToID from 'pages/Survey/utils/scrollToID';

const Music = ({ display = "none" }) => {
  const { setSurvey } = useContext(SurveyContext);
  const { translate } = useI8n();

  const handleMusic = (value) => {
    setSurvey("music_below_threshold_reason", value)
    ScrollToID("scroll-12");
  }

  return (
    <Transition display={display}>
      <SliceDown>
        <Title> {translate("MUSIC_TITLE")}</Title>
        <TagBox 
          text={translate("MUSIC_ADVISE")}
          titleAlign='center'
          >
          <TagButton
            text={translate("WRONG_GENRE")}
            onClick={() => handleMusic("wrongGenre")}
            name="music-opinion"
            label="wrongGenre"
          />
          <TagButton
            text={translate("TOO_LOUD")}
            onClick={() => handleMusic("tooLoud")}
            name="music-opinion"
            label="tooLoud"
          />
          <TagButton
            text={translate("NOT_FAMILIAR")}
            onClick={() => handleMusic("notFamiliar")}
            name="music-opinion"
            label="notFamiliar"
          />
          <TagButton
            text={translate("OTHER_PROBLEM")}
            onClick={() => handleMusic("other")}
            name="music-opinion"
            label="other"
          />
        </TagBox>
      </SliceDown>
    </Transition>
  );
};

export default Music;
