import React, { useContext, useState, useEffect } from 'react';
import CardStyled from 'components/Card.jsx';
import { SurveyContext } from 'providers/survey';
import useI8n from 'i18n/useI18n';
import { Container, Title, Section, Content, Down, Up, List } from './styles';
import Clock from 'resources/img/clock.svg';

const times = [
    { label: '1 min', value: '_1_minutesWait' },
    { label: '5 min', value: '_05_minutesWait' },
    { label: '10 min', value: '_510_minutesWait' },
    { label: '20 min', value: '_1020_minutesWait' },
    { label: '30 min', value: '_2030_minutesWait' },
    { label: '45 min', value: '_3045_minutesWait' },
    { label: '60 min', value: '_4560_minutesWait' },
    { label: '+ 60 min', value: '_60plus_minutesWait' },
];

const StandSurvey = ({ handleProgress }) => {
    const { translate } = useI8n();
    const { setSurvey } = useContext(SurveyContext);

    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState('Select');

    useEffect(() => {
        setSurvey('cx_wait_time', '');
    }, []);

    const handleClick = (time) => {
        setSelected(time.label);
        setSurvey('cx_wait_time', time.value);
        handleProgress('wait', 8, false, true);
        setVisible(!visible);
    };

    const toggle = () => setVisible(!visible);

    return (
        <CardStyled>
            <Container>
                <Title>{translate('HOW_MANY_MINUTES')}</Title>
                <Section>
                    <Content onClick={toggle}>
                        <img src={Clock} alt="clock" />
                        <span>{selected}</span>
                        {visible ? <Up /> : <Down />}
                    </Content>
                </Section>
                {visible && (
                    <List>
                        {times.map((item) => (
                            <p onClick={() => handleClick(item)}>{item.label}</p>
                        ))}
                    </List>
                )}
            </Container>
        </CardStyled>
    );
};

export default StandSurvey;
