import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import usePeople from "../hooks/usePeople";
import { DropdownInput } from "etiquette";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const DropArea = styled.div`
  margin-bottom: 20px;
`;

const Manager = () => {
  const { survey } = useContext(SurveyContext);
  const [handleOptions, options, selected, setSelected] = usePeople(
    "managers",
    "internal_data.manager"
  );

  const { translate } = useI8n();

  const newListManager = options.filter((item) => item.id > 0);

  useEffect(() => {
    if (!selected && newListManager && newListManager.length) {
      setSelected(newListManager[0].id);
    }
  }, [options]);

  return (
    <>
      {!!options && options.length > 0 && (
        <DropArea>
          <DropdownInput
            text={translate("MANAGER")}
            options={newListManager}
            onChange={handleOptions}
            value={selected}
            initialValue={survey?.internal_data?.manager?.id}
          />
        </DropArea>
      )}
    </>
  );
};

export default Manager;
