import ES from "./es"
import EN from "./en"

export const getTranslation = (key, lang) => {
    const dictionary = lang === 1 ? EN : ES;

    return dictionary[key] || (() => {
        console.info("TRANSLATION NOT FOUND FOR: ", `'${key}'`);
        return `**${key}**`
    })()
}

export const langs = ['es', 'en'];