import React, { useContext, useEffect, useRef, useState } from 'react';
import { Transition, TagBox, CircularCheckboxWhite } from 'etiquette';
import { Required, Leyend } from './Style';
import { Space } from 'pages/Survey/components/Space';
import { TextArea } from 'pages/Survey/containers/FoodDrinks';
import { RadioContainer } from 'pages/Survey/containers/RadioContainer';
import { SliceDown } from 'pages/Survey/components/SliceDown';
import Reason from './sections/Reason';
import useI8n from 'i18n/useI18n';
import { SurveyContext } from 'providers/survey';
import Check from './Check';
import { isValidFullNumber } from 'components/Phone/utils/text';
import { ValidatorContext } from 'providers/validator';
import { getPathValue } from 'utils/objectUtils';
import ContactVia from './sections/ContactVia';
import { BusinessContext } from 'providers/business';
import isValidEmail from 'pages/Survey/utils/validEmail';
import EmailLeyends from 'pages/Survey/components/EmailLeyends';
import { findNumber } from 'utils/stringUtils';
import BlueInputCustom from 'components/BlueInputCustom';
import BlueInputMail from 'components/BlueInputMail';


const Form = ({ display = 'display', fadeout }) => {
  const { translate } = useI8n();
  const { survey, setSurvey, setSurveyBulk } = useContext(SurveyContext);
  const { registerAll, errors } = useContext(ValidatorContext);
  const { setBusiness } = useContext(BusinessContext);

  const [vip, setVip] = useState(false);
  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const vipEmailRef = useRef();
  const checkboxRef = useRef();
  const reasonTextRef = useRef();

  useEffect(() => {
    const toRegister = [];

    if (nameRef.current) {
      toRegister.push({
        key: 'contact_name',
        opts: {
          validate: (survey) => {
            return getPathValue(survey, 'contact_cx') === false || getPathValue(survey, 'cx_name').length !== 0;
          },
        },
        ref: nameRef.current,
      });
    }
    if (phoneRef.current) {
      toRegister.push({
        key: 'contact_phone',
        opts: {
          validate: (survey) => {
            return (
              getPathValue(survey, 'contact_via.email') === true ||
              getPathValue(survey, 'contact_cx') === false ||
              isValidFullNumber(getPathValue(survey, 'cx_contact_phone'))
            );
          },
        },
        ref: phoneRef.current,
      });
    }
    if (emailRef.current) {
      toRegister.push({
        key: 'contact_email',
        opts: {
          validate: (survey) => {
            return (
              getPathValue(survey, 'contact_via.phone') === true ||
              getPathValue(survey, 'contact_cx') === false ||
              isValidEmail(getPathValue(survey, 'cx_contact_email'))
            );
          },
        },
        ref: emailRef.current,
      });
    }
    if (vipEmailRef.current) {
      toRegister.push({
        key: 'vip_cx_email',
        opts: {
          validate: (survey) => {
            return (
              !vip ||
              getPathValue(survey, 'contact_via.mail') === true ||
              getPathValue(survey, 'contact_cx') === false ||
              getPathValue(survey, 'contact_cx') === false ||
              isValidEmail(getPathValue(survey, 'vip_cx_email'))
            );
          },
        },
        ref: vipEmailRef.current,
      });
    }
    if (checkboxRef.current) {
      toRegister.push({
        key: 'contact_me',
        opts: {
          validate: (survey) => {
            return (
              getPathValue(survey, 'contact_via.phone') === true || getPathValue(survey, 'contact_via.email') === true || getPathValue(survey, 'contact_cx') === false
            );
          },
        },
        ref: checkboxRef.current,
      });
    }
    if (reasonTextRef.current) {
      toRegister.push({
        key: 'contact_reason_text',
        opts: {
          validate: (survey) => {
            return (
              getPathValue(survey, 'cx_contact_reason') !== 'otherReason' ||
              getPathValue(survey, 'contact_cx') === false ||
              getPathValue(survey, 'contact_other_reason_details') !== ''
            );
          },
        },
        ref: reasonTextRef.current,
      });
    }
    toRegister.push({
      key: 'contact_reason',
      opts: {
        validate: (survey) => {
          return getPathValue(survey, 'contact_cx') === false || getPathValue(survey, 'cx_contact_reason').length !== 0;
        },
      },
      ref: null,
    });

    registerAll(toRegister);
  }, [nameRef.current, phoneRef.current, emailRef.current, vipEmailRef.current, checkboxRef.current, reasonTextRef.current, vip]);

  const handleContact = (businessProp, value) => setBusiness(businessProp, value);

  const handleTextChange = (key, keyAlt, data, businessKey) => {
    if (!findNumber(data)) {
      setBusiness(businessKey, data);
      vip ? setSurveyBulk({ [key]: data, [keyAlt]: data }) : setSurvey(key, data);
    }
  };

  const emailHasError = () => {
    if (!vip) return false;
    if (errors.includes('vip_cx_email') && !isValidEmail(survey?.vip_cx_email)) return true;
    if (errors.includes('vip_cx_email') && isValidEmail(survey?.vip_cx_email) && survey?.vip_cx_email?.trim().length > 0)
      return false;
    if (!isValidEmail(survey?.vip_cx_email) && survey?.vip_cx_email?.trim().length !== 0) return true;
    return false;
  };

  return (
    <Transition display={display} fadeout={fadeout}>
      <SliceDown>
        <Reason reasonTextRef={reasonTextRef} />
        <ContactVia phoneRef={phoneRef} emailRef={emailRef} checkboxRef={checkboxRef} />
        <TagBox text={translate('YOUR_INFO')} titleAlign="center">
          <RadioContainer>
            <CircularCheckboxWhite name="contact-info" text={translate('Mr')} label="Contact-Mr" onClick={() => handleContact('Mr')} margin="0 40px 0 0" />
            <CircularCheckboxWhite name="contact-info" text={translate('Ms')} label="Contact-Ms" onClick={() => handleContact('Ms')} margin="0 40px 0 0" />
            <CircularCheckboxWhite name="contact-info" text={translate('NotD')} label="Contact-NotD" onClick={() => handleContact('Not Disclosed')} margin="0 40px 0 0" />
          </RadioContainer>
          <TextArea>
            <BlueInputCustom
              icon="customer"
              type="text"
              id="blue_firstName"
              value={survey?.cx_name}
              onChange={(e) => handleTextChange('cx_name', 'vip_cx_name', e.target.value, 'name')}
              title={translate('FIRST_NAME')}
              ref={nameRef}
            />
            {errors.includes('contact_name') && (
              <Required>
                <span>*</span>
                <Leyend>{translate('REQUIRED')}</Leyend>
              </Required>
            )}
          </TextArea>
          <Space />
          <TextArea>
            <BlueInputCustom
              icon="customer"
              type="text"
              value={survey?.cx_lastName}
              onChange={(e) => handleTextChange('cx_lastName', 'vip_cx_lastName', e.target.value, 'lastName')}
              title={translate('LAST_NAME')}
            />
          </TextArea>
        </TagBox>
        <Check
          onClick={() => {
            if (!vip) {
              setSurveyBulk({
                vip_cx_name: survey?.cx_name,
                vip_cx_lastName: survey?.cx_lastName,
                vip_cx_email: survey?.cx_contact_email ? survey?.cx_contact_email : '',
              });
            } else {
              setSurveyBulk({
                vip_cx_name: '',
                vip_cx_lastName: '',
                vip_cx_email: '',
              });
            }
            setVip(!vip);
          }}
        />
        {vip && (survey?.contact_via?.email === false) && (
          <>
            <TextArea>
              <BlueInputMail
                textLabel={translate('EMAIL')}
                value={survey?.vip_cx_email}
                onChange={(e) => {
                  setSurvey('vip_cx_email', e.target.value);
                  setBusiness('mail', e.target.value);
                }}
                ref={vipEmailRef}
              />
            </TextArea>
            {(emailHasError() && survey?.vip_cx_email === '') && (
              <Required>
                <Leyend>{translate('REQUIRED')}</Leyend>
              </Required>
            )}
            {survey?.vip_cx_email !== '' && !isValidEmail(survey?.vip_cx_email)  && (
              <Required>
                <Leyend><EmailLeyends string={survey?.vip_cx_email}  /></Leyend>
              </Required>
            )}
          </>
        )}
      </SliceDown>
    </Transition>
  );
};

export default Form;
