import React, {useEffect} from 'react';
import styled from 'styled-components';
import mail from 'resources/img/mail-icon.svg';


const Main = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  border-radius: 8px;
  background-color: #EDEDED;
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
  font-family: 'Montserrat', sans-serif;
  .main__icon {
    width: 51px;
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .main__section {
    width: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }
  .main__section--label {
    width: 100%;
    height: 14px;
    font-size: 14px;
    color: #388CFF;
    padding-left: 7px;
    margin-top: 6px; 
    box-sizing: border-box;
    position: relative;
    p {
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
      font-family: 'Montserrat', sans-serif;
      margin: 0;
      position: absolute;
      top: 13px;
      transition: top 0.15s cubic-bezier(.17,.04,.03,1), font-size 0.15s cubic-bezier(.17,.04,.03,1);
      pointer-events: none;
      font-weight: 400;
    }
  }
  .main__section--input {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    padding: 7px 0 11px 0; 
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
  }
  input {
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #0F191B;
    border: 0;
    outline: none;
    padding-left: 7px;
    box-sizing: border-box;
    background-color: #EDEDED;
  }
  img {
    width: 28px;
    height: 28px;
  }
`;

const BlueInputEmail = React.forwardRef((props, ref) => {
  const labelID = (Math.random() * (1000 - 1) + 1).toFixed(2);

  useEffect(() => {
    if (props.value !== '') {
      const label = document.getElementById(labelID);
      label.style.top = '0px';
      label.style.fontSize = '12px';
    }
  }, [props.value])

  const handleFocus = () => {
    const label = document.getElementById(labelID);
    label.style.top = '0px';
    label.style.fontSize = '12px';
  }
  const handleBlur = () => {
    const label = document.getElementById(labelID);
    if (props.value === '') { 
      label.style.top = '15px';
      label.style.fontSize = '14px';
    }
  } 

  return (
    <Main>
      <div className='main__icon'>
        <img src={mail} alt="" />
      </div>
      <section className='main__section'>
        <div className='main__section--label'>
          <p id={labelID}>{props.textLabel}</p> 
        </div>
        <div className='main__section--input'>
          <input
            type='email'
            value={props.value}
            onChange={props.onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name='frs'
            autoComplete='off'
            required={props.required}
            ref={ref}
          />
        </div>
      </section>
    </Main>
  )
})

export default BlueInputEmail;
