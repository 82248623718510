import React from "react";
import { ItemBoxReverse, SurveyStars } from "etiquette";

const Product = ({ id, src, alt, text, status, stars, setStars, next }) => {
  const handle = (value) => {
    setStars(value);
    next();
  };

  return (
    <ItemBoxReverse
      id={id}
      src={src}
      alt={alt}
      text={text}
      status={status}
      width={"300px"}
      style={{ span: { fontSize: "16px", height: "38px" } }}
    >
      <SurveyStars
        value={stars}
        setValue={(value) => handle(value)}
        size={0.7}
      />
    </ItemBoxReverse>
  );
};

export default Product;
