import React from "react";
import useI8n from "i18n/useI18n";
import { Card } from "etiquette";
import { TextContainer, Subtitle, CustomButton} from "./styles";

const DotellUs = ({ onClick }) => {
  const { translate } = useI8n();
  return (
    <Card width="95%">
      <TextContainer>
        <Subtitle>
          {translate("LEARN_MORE")}
          <strong> dotell.us </strong>
          {translate("SURVEY_SYSTEM")}
        </Subtitle>
      </TextContainer>
      <CustomButton onClick={onClick} text={translate("YES_LEARN")} />
    </Card>
  );
};

export default DotellUs;