import styled from 'styled-components';
import {CustomText} from "etiquette";

export const TitleContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  p {
    text-align: center;
  }
`;
  
export const InputContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: start;
`;

export const Required = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  color: red;
  span {
    font-size: 21px;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Leyend = styled(CustomText)`
  font-size: 12px;
  color: inherit;
  margin-left: 5px;
`;
