import styled from 'styled-components';

export const SubtitleArea = styled.div`
  width: 100%;
  p {
    text-align: center;
  }
`;

export const InfoArea = styled.div`
  width: 100%;
  margin-Bottom: 32px;
  p {
    text-align: center;
  }
`;

export const ColumnsArea = styled.div`
  width: 100%;
  margin-Bottom: 32px;
  display: flex;
  justify-content: center;
  p {
    margin: 0;
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600&display=swap');
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    text-align: center;
  }
`;

export const RateContainer = styled.div`
  width: 100%;
  margin-Bottom: 15px;
`;

export const TextArea = styled.div`
  width: 100%;
  text-align: start;
`;