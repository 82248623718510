import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import useI8n from "i18n/useI18n";
import Navbar from "components/Navbar";
import Container from "containers/Container.jsx";
import InternalArea from "containers/InternalArea.jsx";
import TwoCols from "./container/TwoCols";
import PreviewTitle from "./container/PreviewTitle";
import Manager from "./components/Manager";
import Operator from "./components/Operator";
import Ticket from "./components/Ticket";
import Table from "./components/Table";
import Waiter from "./components/Waiter";
import Amount from "./components/Amount";
import Guests from "./components/Guests";
import { Card, Button, Loading} from "etiquette";
import useLogin from "hooks/useLogin";
import { SurveyContext } from "providers/survey";
import { BusinessContext } from "providers/business";

const Preview = () => {
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { survey, sendSurvey } = useContext(SurveyContext);
  const { resetBusiness } = useContext(BusinessContext)
  const { translate } = useI8n();
  const doLogin = useLogin();

  useEffect(() => {
    doLogin();
    resetBusiness();
  }, []);

  const handleSubmit = () => {
    const ticketnumber = survey?.internal_data?.ticket_n;
    if (!ticketnumber) {
      alert(translate("TICKET_MISSING"));
      return;
    }

    sendSurvey("started");
    history.push("survey");
  };

  return (
    <>
      <Navbar />
      <Container>
        <Card>
          <InternalArea>
            <PreviewTitle> {translate("CHECK_DATA")} </PreviewTitle>
            <Manager />
            <Operator />
            <TwoCols>
              <div className="col">
                <Ticket
                  setIsButtonDisabled={setIsButtonDisabled}
                  setIsLoading={setIsLoading}
                />
              </div>
              <div className="col">
                <Table />
              </div>
            </TwoCols>
            <Waiter />
            <TwoCols>
              <div className="col">
                <Amount />
              </div>
              <div className="col">
                <Guests />
              </div>
            </TwoCols>
            {isLoading ? (
              <Loading color='#388CFF' margin='0px auto 20px' />
            ) : (
              <Button
                text={translate("START_SURVEY")}
                onClick={handleSubmit}
                type="submit"
                style={{ marginBottom: "30px" }}
                disabled={isButtonDisabled}
                width="60%"
              />
            )}
          </InternalArea>
        </Card>
      </Container>
    </>
  );
};

export default Preview;
