import React from "react";
import { Button } from "etiquette";

const Submit = ({ onClick, text }) => (
  <Button
    text={text}
    onClick={onClick}
    type="submit"
    style={{ marginBottom: "40px" }}
    width="60%"
  />
);

export default Submit;