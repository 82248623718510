import React from "react";
import { Button } from "etiquette";
import useI8n from "i18n/useI18n";

const Submit = ({ onClick }) => {
  const { translate } = useI8n();
  return (
    <Button
      text={translate("LOGIN")}
      onClick={onClick}
      type="submit"
      style={{ marginBottom: "40px" }}
      width="60%"
    />
  );
};

export default Submit;
