import { useState } from 'react'

const useVisible = () => {
  const [visible, setVisible] = useState('none');
  const [transition] = useState(false);

  const handle = (rate) => {
      if (rate>0 && rate<=4) {
        setVisible('flex')
      }else {
        setVisible('none')
      } 
  }

  return [visible, handle, transition]
};

export default useVisible;