import React, { useContext } from "react";
import {
  TitleArea,
  InfoArea,
  ImgArea,
  SpanArea,
} from "pages/Survey/containers/HostManager";
import {
  Subtitle,
  Info,
  CircularImage,
  Span,
  StarBox,
  SurveyStars,
} from "etiquette";
import {
  helpfulness,
  friendlyTags,
  tagManagerList,
} from "./manager_attributes_rating";
import {
  ImgContainer,
  IndividualContainer,
} from "pages/Survey/containers/HostManager";
import CardStyled from "components/Card";
import useVisible from "../../hooks/useVisible.js";
import Manager from "./Manager";
import useI8n from "i18n/useI18n";
import { PERSONS_IMG_URL } from "resources/constants/urls";
import { DataContext } from "providers/data";
import { SurveyContext } from "providers/survey";
import { ResetTags } from "pages/Survey/utils/resetTags";
import { textTransformCapitalize } from "utils/stringUtils";
import ScrollToID from "pages/Survey/utils/scrollToID";

const ManagerSurvey = ({ handleProgress }) => {
  const { data } = useContext(DataContext);
  const { survey, setSurvey, setSurveyBulk } = useContext(SurveyContext);
  const [visible, handle, transition] = useVisible();
  const { translate } = useI8n();

  const handleSelectPerson = (id, name) => {
    const manager_selected = {
      idManager: id,
      nameManager: name,
    };
    setSurvey("manager_selected", manager_selected);
    handleProgress("managerIMG", 8);
  };

  const handleStars = (value) => {
    const sameValue = value === survey?.manager_basic_rating?.stars;
    const zeroFive = sameValue || value === 5;
    const move = value === 5 && !sameValue;

    if (zeroFive) {
      handle(0);
      setSurveyBulk({
        "manager_attributes_rating.helpfulness": { ...helpfulness },
        "manager_attributes_rating.friendlyTags": { ...friendlyTags },
        manager_basic_rating: {
          stars: sameValue ? 0 : 5,
          no_opinion: false,
        },
      });
      ResetTags(tagManagerList);
    } else {
      handle(value);
      setSurvey("manager_basic_rating", {
        stars: value,
        no_opinion: false,
      });
    }

    handleProgress("managerStars", 9, sameValue, move);
    ScrollToID("managerStar");
  };

  const getSelectedManagerName = () => {
    const status = survey?.manager_selected?.nameManager;
    const managerName = status
      ? textTransformCapitalize(survey.manager_selected.nameManager)
      : "";
    return status
      ? `${translate("RATE")}
        ${
          survey?.manager_selected?.idManager < 0
            ? ""
            : ` ${translate("RATE_CONNECTOR")} ${managerName}`
        }`
      : translate("NOT_RATED");
  };

  const managerFilter = () => {
    const length = data?.people?.managers?.length;

    if (!length) return [];

    const managers = data?.people?.managers?.filter(
      (manager) => manager.userId !== -4
    );
    const ITEMS_THRESHOLD = 5;
    length >= ITEMS_THRESHOLD && moveDontRememberToMiddle(managers);

    return managers;
  };

  const moveDontRememberToMiddle = (managers) => {
    const dontRemember = managers.pop();
    managers.splice(2, 0, dontRemember);
  };

  return (
    <>
      <CardStyled>
        <TitleArea>
          <Subtitle>{translate("RATE_MANAGER")}</Subtitle>
        </TitleArea>
        <InfoArea>
          <Info>{translate("SELECT_MANAGER")}</Info>
        </InfoArea>
        <ImgArea>
          <ImgContainer>
            {!!data.people?.managers?.length &&
              managerFilter().map((item) => {
                const noManager = item.userId < 0;
                return (
                  <IndividualContainer>
                    <CircularImage
                      name="managerImgList"
                      text={
                        noManager
                          ? translate(item.name)
                          : textTransformCapitalize(item.name)
                      }
                      onClick={() => handleSelectPerson(item.userId, item.name)}
                      value={item.name}
                      label={"manager" + item.userId}
                      img={PERSONS_IMG_URL + item.userId + ".png"}
                      key={"manager" + item.userId}
                    />
                  </IndividualContainer>
                );
              })}
          </ImgContainer>
        </ImgArea>
        <SpanArea>
          <Span margin="0px">{getSelectedManagerName()}</Span>
        </SpanArea>
        <div id="managerStar"></div>
        <StarBox>
          <SurveyStars
            value={survey?.manager_basic_rating?.stars}
            setValue={handleStars}
          />
        </StarBox>
        <Manager display={visible} fadeout={transition} />
      </CardStyled>
    </>
  );
};

export default ManagerSurvey;
