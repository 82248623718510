import React from "react";
import useI8n from "i18n/useI18n";
import { Box, Container } from "./styles";
import Success from "./Success";
import DotellUs from './DotellUs';

const EnjoyVisit = ({ onClick }) => {
  const { translate } = useI8n();
  return (
    <Box>
      <Container>
        <Success
          title={`${translate("THANK_YOU_TIME")}`}
          subtitle={translate("ENJOY_VISIT")}
        />
        <DotellUs onClick={onClick} />
      </Container>
    </Box>
  );
};

export default EnjoyVisit;