import cuid from 'cuid';
import moment from 'moment';



const rejectedSurvey = {
    visit_date: moment().format(),
    survey_config: {
        delimiter: "",
        status: "rejected",
        fingerprint: cuid(),
        flow: "internal"
    },
    skip_reasons: [""]
}

export default rejectedSurvey;