import styled from 'styled-components';

const Container = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 1024px) {
    width: 70%;
  }
  @media (max-width: 769px) {
    width: 76%;
  }
  @media (max-width: 678px) {
    margin-top: 0px;
  }
`;

export default Container;