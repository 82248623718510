export const communication = {
    great: false,
    ok: false,
    poor: false,
};

export const friendlyTags = {
    superFriendly: false,
    friendly: false,
    unfriendly: false,
};

export const smilingTags = {
    smiling: false,
    notSmiling: false,
    frowning: false,
}

export const tagHostList = ["HAsimiling", "HAnotSmiling", "HAfrowning", "HDsuperFriendly", "HDfriendly", "HDunfriendly", "HCgreat", "HCok", "HCpoor"];

