import React, { useContext, useEffect } from "react";
import { CustomInput } from "etiquette";
import { SurveyContext } from "providers/survey";
import { DataContext } from "providers/data";
import useI8n from "i18n/useI18n";

const Ticket = ({ setIsButtonDisabled, setIsLoading }) => {
  const { translate } = useI8n();
  const { survey, setSurvey, setSurveyBulk } = useContext(SurveyContext);
  const { data } = useContext(DataContext);
  const { loadTicket } = useContext(DataContext);

  useEffect(() => {
    if (!survey?.internal_data?.ticket_n) {
      setIsButtonDisabled(true);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setIsLoading(true);
      
      loadTicket(survey?.internal_data?.ticket_n)
        .catch((e) => {
          setIsButtonDisabled(true);
          alert(e);
        })
        .finally(() => setIsLoading(false));
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [survey?.internal_data?.ticket_n]);

  useEffect(() => {
    processTicketInfo();
  }, [data.ticket]);

  const processTicketInfo = () => {
    if (data.ticket.data) {
      const firstItem = data.ticket.data[0];
      const table_n = firstItem["Mesa"]; //internal_data.table_n
      const guests = firstItem["Comensales"];
      const amount = firstItem["total"];
      const waiterId = firstItem["UserId"];
      const waiterName = firstItem["Usuario"];
      const visit_date = firstItem["opening_datetime"];
      
      setSurveyBulk({
        "internal_data.table_n": table_n,
        "amount": amount,
        "guests": guests,
        "internal_data.waiter": {
          id: waiterId,
          name: waiterName,
        },
        visit_date: visit_date,
      });

      setIsButtonDisabled(false);
    }
  };

  return (
    <CustomInput
      type="number"
      icon="hashtag"
      title={translate("NUM_TICKET")}
      IconPosition="start"
      placeholder=". . ."
      value={survey?.internal_data?.ticket_n}
      onChange={(e) => setSurvey("internal_data.ticket_n", e.target.value)}
    />
  );
};

export default Ticket;
