export const TICKET = process.env.REACT_APP_TICKET;

export const PRODUCTS = process.env.REACT_APP_PRODUCTS;

export const USERS = process.env.REACT_APP_USERS;

export const CATEGORIES = process.env.REACT_APP_CATEGORIES;

export const SAVE = process.env.REACT_APP_SAVE;

export const REJECTED = process.env.REACT_APP_REJECTED;

export const BUSINESS = process.env.REACT_APP_BUSINESS;

export const PERSONS_IMG_URL = process.env.REACT_APP_PERSONS_IMG_URL;

export const PRODUCT_IMG_URL = process.env.REACT_APP_PRODUCT_IMG_URL;

export const DOTELL_URL = process.env.REACT_APP_DOTELL_URL;
