import styled from 'styled-components';
import {Card} from 'etiquette';

const CardStyled = styled(Card)`
    && {display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    margin: 10px auto;
    flex-direction: column;
    box-sizing: border-box;
    }
`;

export default CardStyled;

