import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EnjoyVisit from './components/EnjoyVisit';
import LearnMore from './components/LearnMore';
import RecivedInfo from './components/RecivedInfo';
import Navbar from "components/Navbar";
import { BusinessContext } from 'providers/business';
import useLogin from "hooks/useLogin";


const Gratefulness = () => {
  const doLogin = useLogin();

  useEffect(() => {
    doLogin();
  }, []);

  const { business } = useContext(BusinessContext);
  const history = useHistory();

  const infoBusinessRecived = !!business?.company && business?.company !== ''

  const contactInfo= business?.mail === "" && 
                   business?.phone === ""

  const handleBusinessForm = () => history.push("business");

  return (
    <>
    <Navbar inline />
    { infoBusinessRecived 
    ? <RecivedInfo onClick={handleBusinessForm} /> 
    : contactInfo 
        ? <EnjoyVisit onClick={handleBusinessForm} /> 
        : <LearnMore onClick={handleBusinessForm} name={business?.name}/>
    }
    </>
  )
}

export default Gratefulness;
