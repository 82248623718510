import React, { useState, useEffect } from "react";
import Select from "react-select";
import { formatOptionLabel, formatGroupLabel, SingleValue, Group, StylesObject } from "./style.jsx";
import { getGroupedOptions, findCountry } from './data.js';

const CountrySelector = ({preferredCountriesCodes, value, onChange}) => {

  const [options, setOptions] = useState([]);
  const [country, setCountry] = useState("52");

  useEffect(()=>{
    const groupedOptions = getGroupedOptions(preferredCountriesCodes, "ALL_COUNTRIES");
    setOptions(groupedOptions);
    const country = findCountry(value);
    if(!!country) setCountry(country);
  }, []);

  const handleChange = (country) => {
    setCountry(country); 
  }

  useEffect(() => {
    if(!country) return;
      onChange(country.phone);
  }, [country]);

  return (
      <div>
          {options.length !== 0 && country && (
              <Select
                  options={options}
                  value={country}
                  onChange={handleChange}
                  components={{ SingleValue, Group }}
                  isSearchable={false}
                  styles={StylesObject}
                  formatGroupLabel={formatGroupLabel}
                  formatOptionLabel={formatOptionLabel}
              />
          )}
      </div>
  );
}

export default CountrySelector;
