import moment from "moment"

const Fecha = moment().format();

const TEST_TICKET = [
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 3277,
      "item_name": "EDAMAMES.",
      "Cantidad": 1,
      "TotalProducto": 190,
      "Familia": "ENTRADAS",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838146,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 6969,
      "item_name": "CHIRASHI BOWL",
      "Cantidad": 1,
      "TotalProducto": 190,
      "Familia": "ENTRADAS",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838146,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 481,
      "item_name": "400 CONEJO JOVEN.",
      "Cantidad": 1,
      "TotalProducto": 190,
      "Familia": "MEZCAL CP",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838146,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 1197,
      "item_name": "BUI MIN GDE",
      "Cantidad": 1,
      "TotalProducto": 110,
      "Familia": "BEBIDAS S/A",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838097,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 1196,
      "item_name": "BUI NAT GDE",
      "Cantidad": 1,
      "TotalProducto": 110,
      "Familia": "BEBIDAS S/A",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838096,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 1124,
      "item_name": "EMMALEMA",
      "Cantidad": 1,
      "TotalProducto": 280,
      "Familia": "COC DRAGONES",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838140,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 817,
      "item_name": "PAPAS ROMERO QUESO",
      "Cantidad": 1,
      "TotalProducto": 145,
      "Familia": "ENTRADAS",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838135,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 817,
      "item_name": "PAPAS ROMERO QUESO",
      "Cantidad": 1,
      "TotalProducto": 145,
      "Familia": "ENTRADAS",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838158,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 819,
      "item_name": "PULPO BRASAS",
      "Cantidad": 1,
      "TotalProducto": 280,
      "Familia": "ENTRADAS",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838134,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 3278,
      "item_name": "ROCK SHRIMP TEMPURA",
      "Cantidad": 1,
      "TotalProducto": 260,
      "Familia": "SUSHI",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838136,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 1139,
      "item_name": "FROZEN TULUM MOJITO",
      "Cantidad": 1,
      "TotalProducto": 180,
      "Familia": "MIXOLOGIA",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 1338,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 1118,
      "item_name": "BERRY CHANTY",
      "Cantidad": 1,
      "TotalProducto": 180,
      "Familia": "MIXOLOGIA",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838141,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 1138,
      "item_name": "SPICY MARGARITA",
      "Cantidad": 1,
      "TotalProducto": 180,
      "Familia": "MIXOLOGIA",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838141,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 415,
      "item_name": "TANQUERAY",
      "Cantidad": 1,
      "TotalProducto": 180,
      "Familia": "GINEBRA CP",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838142,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 415,
      "item_name": "TANQUERAY",
      "Cantidad": 1,
      "TotalProducto": 180,
      "Familia": "GINEBRA CP",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838144,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 834,
      "item_name": "TEXAS CUT RES 250GR",
      "Cantidad": 1,
      "TotalProducto": 450,
      "Familia": "PLATO FUERTE",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838159,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 835,
      "item_name": "TEXAS CUT RES 350GR",
      "Cantidad": 1,
      "TotalProducto": 570,
      "Familia": "PLATO FUERTE",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838138,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 2474,
      "item_name": "TOPO CHICO AGUA MINERAL",
      "Cantidad": 1,
      "TotalProducto": 60,
      "Familia": "BEBIDAS S/A",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838161,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   },
   {
      "Fecha": Fecha,
      "order_type_description": "MESA",
      "environment_description": "EAST ROOFTOP",
      "Mesa": 9999,
      "UserId": 1336,
      "Usuario": "FERNANDOGUERRERO BAUTISTA",
      "Estado": "Abierta",
      "Comensales": 5,
      "Tiempo": "46 Min",
      "total": 3580,
      "product_id": 4820,
      "item_name": "YAKIMESHI MIXTO",
      "Cantidad": 2,
      "TotalProducto": 440,
      "Familia": "SUSHI",
      "order_id": 111743,
      "suborder_id": 118622,
      "order_product_id": 838137,
      "TicketNumber": null,
      "opening_datetime": Fecha,
   }
]

export default TEST_TICKET;