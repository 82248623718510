export default function filterByCategory(categories, data) {

    const productMap = new Map();
    const filtered = !!data ? data.filter((product) => !!categories[product.Familia]) : [];

    filtered.forEach((product)=>{
        productMap.set(`${product.product_id}`, {...product});
    });

  return [...productMap.values()].map((item) => ({
      id: item.product_id,
      name: item.item_name,
      family_en: item.Familia,
      name_en: item.item_name,
      rating: 0,
    }));
}
