import React from "react";
import { Modal, Button, Title, Info } from "etiquette";
import styled from 'styled-components';
import useI8n from "i18n/useI18n";

const ClearButton = styled(Button)`
 background-color: #EEFBFF;
 color: #388CFF;
 border: 2px solid #EEFBFF;
 width: min(200px , 100%);
`;
const RegularButton = styled(Button)`
  width: min(200px , 100%);
`;
const Container = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 padding: 10px 20px 60px;
`;
const Row = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 25px;
    align-items: center;
    justify-content: center;
    width: 85%;
    padding-top: 30px;
`;
const TextContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 width: min(240px , 100%);
 `;
const Space = styled.div`
    height: ${({height})=>(height || "15px")};
`;

const Finished = ({ display = "none", onClick, fadeout, percent, validation }) => {
    const { translate } = useI8n();

    return (
    <Modal display={display} onClick={onClick} fadeout={fadeout} wcard='85%'>
        <Container>
            <TextContainer>
                <Title style={{fontWeight: '600'}}>{translate("FINISHED")}</Title>
                <Space height="22px"/>
                <Info style={{margin: '20px auto', textAlign: 'center'}}> {translate("YOUR_PERCENT")} {percent}</Info>
                <Space />
            </TextContainer>
            
            <Row>
                <ClearButton text={translate("YES_DONE")} onClick={validation}/>
                <RegularButton text={translate("NO_GOBACK")} onClick={onClick}/>
            </Row>
        </Container>
    </Modal>
    );
  };
  
  export default Finished;
  