import React from "react";
import styled from "styled-components";
import { SquareCheckbox } from "etiquette";
import useI8n from "i18n/useI18n";

const CheckArea = styled.div`
  margin: 25px 0 0 0;
  display: flex;
  justify-content: flex-start;
  text-align: left;
`;

const Check = ({ onClick, checked }) => {
  const { translate } = useI8n();
  return (
    <CheckArea>
      <SquareCheckbox
        text={translate("VIP_PROMO")}
        onClick={onClick}
        checked={checked}
        style={{ marginBottom: "30px" }}
      />
    </CheckArea>
  );
};

export default Check;