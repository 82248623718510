import React, { useContext } from "react";
import {
  TitleArea,
  InfoArea,
  ImgArea,
  SpanArea,
} from "pages/Survey/containers/HostManager";
import {
  Subtitle,
  Info,
  CircularImage,
  Span,
  StarBox,
  SurveyStars,
} from "etiquette";
import {
  communication,
  friendlyTags,
  smilingTags,
  tagHostList,
} from "./hosting_attributes_rating";
import {
  ImgContainer,
  IndividualContainer,
} from "pages/Survey/containers/HostManager";
import CardStyled from "components/Card";
import useVisible from "pages/Survey/hooks/useVisible";
import Hostess from "./Hostess";
import useI8n from "i18n/useI18n";
import { PERSONS_IMG_URL } from "resources/constants/urls";
import { DataContext } from "providers/data";
import { SurveyContext } from "providers/survey";
import { ResetTags } from "pages/Survey/utils/resetTags";
import { textTransformCapitalize } from "utils/stringUtils";
import ScrollToID from "pages/Survey/utils/scrollToID";

const HostSurvey = ({ handleProgress }) => {
  const { data } = useContext(DataContext);
  const { survey, setSurvey, setSurveyBulk } = useContext(SurveyContext);
  const [visible, handle, transition] = useVisible();
  const { translate } = useI8n();

  const handleSelectPerson = (id, name) => {
    const hostess_selected = {
      idHostess: id,
      nameHostess: name,
    };
    setSurvey("hostess_selected", hostess_selected);
    handleProgress("hostessIMG", 5);
  };

  const handleStars = (value) => {
    const sameValue = value === survey?.hosting_basic_rating?.stars;
    const zeroFive = sameValue || value === 5;
    const move = value === 5 && !sameValue;

    if (zeroFive) {
      handle(0);
      setSurveyBulk({
        "hosting_attributes_rating.smilingTags": { ...smilingTags },
        "hosting_attributes_rating.friendlyTags": { ...friendlyTags },
        "hosting_attributes_rating.communication": { ...communication },
        hosting_basic_rating: {
          stars: sameValue ? 0 : 5,
          no_opinion: false,
        },
      });
      ResetTags(tagHostList);
    } else {
      handle(value);
      setSurvey("hosting_basic_rating", {
        stars: value,
        no_opinion: false,
      });
    }

    handleProgress("hostessStars", 6, sameValue, move);
    ScrollToID("hostStar");
  };

  const getSelectedHostessName = () => {
    const status = survey?.hostess_selected?.nameHostess;
    const hostessName = status
      ? textTransformCapitalize(survey.hostess_selected.nameHostess)
      : "";
    const currentId = survey?.hostess_selected?.idHostess;
    return status
      ? `${translate("RATE")}
        ${
          currentId < 0 || currentId === 999
            ? ""
            : ` ${translate("RATE_CONNECTOR")} ${hostessName}`
        }`
      : translate("NOT_RATED");
  };

  const hostFilter = () => {
    const length = data.people?.hostess?.length;
    if (!length) return [];

    const ITEMS_THRESHOLD = 5;

    const hostess = data.people.hostess.filter((item) => item.userId > 0);
    length >= ITEMS_THRESHOLD && moveDontRememberToMiddle(hostess);
    return hostess;
  };

  const moveDontRememberToMiddle = (hostess) => {
    const dontRemember = hostess.pop();
    hostess.splice(2, 0, dontRemember);
  };

  return (
    <>
      <CardStyled>
        <TitleArea>
          <Subtitle>{translate("RATE_HOSTESS")}</Subtitle>
        </TitleArea>
        <InfoArea>
          <Info>{translate("SELECT_HOST")}</Info>
        </InfoArea>
        <ImgArea>
          <ImgContainer>
            {!!data.people?.hostess?.length &&
              hostFilter().map((item) => {
                const noHost = item.userId === 999;
                return (
                  <IndividualContainer>
                    <CircularImage
                      name="hostessImgList"
                      text={
                        noHost
                          ? translate(item.name)
                          : textTransformCapitalize(item.name)
                      }
                      onClick={() => handleSelectPerson(item.userId, item.name)}
                      value={item.name}
                      label={item.userId}
                      img={PERSONS_IMG_URL + item.userId + ".png"}
                      key={"hostess" + item.userId}
                    />
                  </IndividualContainer>
                );
              })}
          </ImgContainer>
        </ImgArea>
        <SpanArea>
          <Span margin="0px">{getSelectedHostessName()}</Span>
        </SpanArea>
        <div id="hostStar"></div>
        <StarBox>
          <SurveyStars
            value={survey?.hosting_basic_rating?.stars}
            setValue={handleStars}
          />
        </StarBox>
        <Hostess display={visible} fadeout={transition} />
      </CardStyled>
    </>
  );
};

export default HostSurvey;
