import React, { useState, useContext, useEffect } from "react";
import useI8n from "i18n/useI18n";
import { useHistory } from "react-router-dom";
import { ProgressBox, Hr } from "etiquette";
import Navbar from "components/Navbar";
import Section, { LastSection } from "./containers/Section";
import Overall from "./Sections/Overall";
import Perfect from "./Sections/Perfect";
import Server from "./Sections/Server/index";
import EstimatedTime from "./Sections/EstimatedTime";
import Food from "./Sections/Food";
import Drink from "./Sections/Drinks";
import Host from "./Sections/Hostess";
import Reservation from "./Sections/Reservation";
import Manager from "./Sections/Manager";
import Chef from "./Sections/Chef";
import Music from "./Sections/Music";
import Contact from "./Sections/Contact";
import Stand from "./Sections/Stand";
import QuinceCrowd from "./Sections/QuinceCrowd";
import ModalFinished from "./modals/EndConfirmation";
import Goal from "./Sections/Goal";
import ProgressBar from "./utils/progressBar";
import useLogin from "hooks/useLogin";
import { SurveyContext } from "providers/survey";
import { ValidatorContext } from "providers/validator";
import EmptyContactForms from "./utils/emptyForm";
import ScrollView from "./utils/scrollIntoViewport";

const Survey = () => {
  const doLogin = useLogin();

  useEffect(() => {
    doLogin();
  }, []);

  const history = useHistory();
  const { translate, selectedLang } = useI8n();
  const { survey, sendSurvey } = useContext(SurveyContext);
  const { validate } = useContext(ValidatorContext);

  const [verify, setVerify] = useState([]);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [transition, setTransition] = useState(false);
  const visible = show ? "flex" : "none";

  const [progressText, setProgressText] = useState(translate("PROGRESS_0"));

  const totalQuestions = 15;
  const plus = Math.floor(100 / totalQuestions);
  const max = totalQuestions * plus;
  const progress = count < 1 ? "0" : count < max ? count : "100";

  useEffect(() => {
    setProgressText(translate(ProgressBar(progress)));
  }, [progress, selectedLang]);

  const handleProgress = (blame, itemId, sameValue, move) => {
    const newVerify = [...verify];

    if (!newVerify.includes(blame)) {
      newVerify.push(blame);
      setVerify(newVerify);
      setCount(count + plus);
    } else if (sameValue) {
      setVerify(newVerify.filter((item) => item !== blame));
      setCount(count - plus);
    }

    move && ScrollView("progress", `scroll-${itemId + 1}`);
  };

  const handleSendSurvey = () => {
    if (!validate(survey)) return;
    progress === "100" ? validation() : setShow(true);
    setTransition(false);
  };

  const validation = () => {
    const noUserData =
      survey?.contact_cx &&
      ((survey?.cx_contact_phone === "" && survey?.cx_contact_email === "") ||
        survey?.cx_name === "" ||
        survey?.cx_lastName === "");

    const contactForm = noUserData ? EmptyContactForms : {};
    farewell(contactForm);
  };

  const farewell = (postUpdate = {}) => {
    progress !== "100" && handleHideTransition();

    const surveyProgress = progress === "0" ? "rejected" : "filled";
    sendSurvey(surveyProgress, postUpdate);

    history.push("gratefulness");
  };

  const handleHideTransition = () => {
    setTransition(true);
    setShow(false);
  };

  return (
    <>
      <Navbar inline />

      <ModalFinished
        display={visible}
        onClick={handleHideTransition}
        validation={validation}
        fadeout={transition}
        percent={`${progress}%`}
      />
      <ProgressBox
        text={progressText}
        completed={progress}
        textButton={translate("END_SURVEY")}
        onClick={handleSendSurvey}
        id="progress"
      >
        <Goal />

        <Section id="scroll-0">
          <Overall handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-1">
          <Perfect handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-2">
          <Server handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-3">
          <EstimatedTime handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-4">
          <Food handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-5">
          <Drink handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-6">
          <Host handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-7">
          <Reservation handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-8">
          <Stand handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-9">
          <Manager handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-10">
          <Chef handleProgress={handleProgress} />
        </Section>

        <Section id="scroll-11">
          <Music handleProgress={handleProgress} />
        </Section>

        <LastSection id="scroll-12">
          <Contact handleProgress={handleProgress} />
        </LastSection>
        {!survey.contact_cx && (
          <>
            <Hr/>
            <LastSection>
              <QuinceCrowd />
            </LastSection>
          </>
        )}
      </ProgressBox>
    </>
  );
};

export default Survey;
