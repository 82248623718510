import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { CircularCheckboxWhite, CustomText } from 'etiquette';
import { Box, Div, UniversalText, InputContainer, CancelContainer, Icon, SubmitContainer } from './style';
import Navbar from 'components/Navbar';
import { RadioContainer } from 'pages/Survey/containers/RadioContainer';
import { Space } from 'pages/Survey/components/Space';
import useI8n from 'i18n/useI18n';
import CardStyled from 'components/Card';
import CancelIcon from 'resources/img/back-icon.svg';
import Submit from 'components/Submit';
import { businessContact } from 'resources/api';
import { MobileInput } from 'components/Phone';
import { getFullPhoneNumber, isValidNumber } from 'components/Phone/utils/text';
import { BusinessContext } from 'providers/business';
import { Required } from 'pages/Survey/Sections/Contact/Style';
import { Leyend } from 'pages/Survey/Sections/Contact/Style';
import { parsePhoneNumber } from 'react-phone-number-input';
import useLogin from "hooks/useLogin";
import isValidEmail from 'pages/Survey/utils/validEmail';
import EmailLeyends from 'pages/Survey/components/EmailLeyends';
import BlueInputMail from 'components/BlueInputMail';
import BlueInputCustom from 'components/BlueInputCustom';
import phoneFormat from 'utils/phoneFormat';
import { findNumber } from 'utils/stringUtils';


const Business = () => {
  const doLogin = useLogin();

  useEffect(() => {
    doLogin();  
  }, []);
  const { translate, selectedLang } = useI8n();
  const { business, setBusiness } = useContext(BusinessContext);
  const history = useHistory();

  const [info, setInfo] = useState({});
  const [state, setState] = useState({ number: '', country: '52' });

  const miniPhoneFormat = (string) => string.slice(0,2)+'-'+string.slice(2,6)+'-'+string.slice(6);

  useEffect(() => {
    const tmpInfo = { ...info };
    tmpInfo['pronoun'] = business.gender;
    tmpInfo['name'] = business.name;
    tmpInfo['lastName'] = business.lastName;
    tmpInfo['mail'] = business.mail;
    tmpInfo['phone'] = business.phone;
    setInfo(tmpInfo);
    try {
      const parsedNumber = parsePhoneNumber(business.phone);     
      setState({number: miniPhoneFormat(parsedNumber.nationalNumber), country: parsedNumber.countryCallingCode});
    } catch(e){}
  }, []);

  const handleInfo = (property, value) => {
    setBusiness(property, value);
    const tmpInfo = { ...info };
    tmpInfo[property] = value;
    setInfo(tmpInfo);
  };

  const handleInfoText = (property, value) => {
    if (!findNumber(value)) {
      setBusiness(property, value);
      const tmpInfo = { ...info };
      tmpInfo[property] = value;
      setInfo(tmpInfo);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidNumber(state.country, state.number)) return;
    const data = {
      inquiry_config: {
        lang: selectedLang === 1 ? 'EN' : 'ES',
        flow: 'internal',
      },
      first_name: info.name ?? '',
      last_name: info.lastName ?? '',
      email: info.mail ?? '',
      phone_number: !state.country || !state.number ? '' : getFullPhoneNumber(state.country, state.number),
      company_name: info.company ?? '',
      type_of_business: info.typeBusiness ?? '',
      locations: info.places ?? '',
      pronoun: info.pronoun ?? '',
      city: info.location ?? '',
      position: info.businessPosition ?? '',
      inquiry_details: '',
    };
    businessContact(data);
    handleOut(false)
  };

  const handlePhoneChange = (country, number) => {
    phoneFormat(number, state )
    .then((response)=>{
      setState({ country, number: response });
    })
  };

  const handleOut = (setCompany) => {
    setCompany && setBusiness('company', "");
    history.push("gratefulness");
  }

  return (
    <>
      <Navbar inline />
      <Box onSubmit={handleSubmit}>
        <CancelContainer onClick={(e) => handleOut(true)}>
          <Icon src={CancelIcon} />
          <CustomText>Cancel</CustomText>
        </CancelContainer>
        <CardStyled>
          <Div margin="32px 0">
            <UniversalText className="title">
              {translate("BUSINESS_TITLE")}
            </UniversalText>
          </Div>
          <Div margin="0 0 24px 0">
            <UniversalText className="title">
              {translate("YOUR_INFO")}
            </UniversalText>
          </Div>
          <RadioContainer>
            <CircularCheckboxWhite
              name="business-radio"
              text={translate("Mr")}
              label="Mr"
              onClick={() => handleInfo("pronoun", "Mr")}
              checked={info.pronoun === "Mr" ? true : false}
              margin="0 40px 0 0"
            />
            <CircularCheckboxWhite
              name="business-radio"
              text={translate("Ms")}
              label="Ms"
              onClick={() => handleInfo("pronoun", "Ms")}
              checked={info.pronoun === "Ms" ? true : false}
              margin="0 40px 0 0"
            />
            <CircularCheckboxWhite
              name="business-radio"
              text={translate("NotD")}
              label="NotD"
              onClick={() => handleInfo("pronoun", "Not Disclosed")}
              checked={info.pronoun === "Not Disclosed" ? true : false}
            />
          </RadioContainer>
          <InputContainer>
            <BlueInputCustom 
              icon="customer"
              type="text"
              value={info.name}
              onChange={(e) => handleInfoText("name", e.target.value)}
              title={translate("FIRST_NAME")}
            />
            <Space />
            <BlueInputCustom
              icon="customer"
              type="text"
              value={info.lastName}
              onChange={(e) => handleInfoText("lastName", e.target.value)}
              title={translate("LAST_NAME")}
            />
            <Space />
            <BlueInputMail
              textLabel={translate("EMAIL")}
              value={info.mail || ""}
              onChange={(e) => handleInfo("mail", e.target.value)}
              required={true}
            />
            {info.mail !== "" && !isValidEmail(info.mail) && (
              <Required>
                <Leyend>
                  <EmailLeyends string={info.mail} />
                </Leyend>
              </Required>
            )}
            <Space />

            <MobileInput
              title={translate("MOBILE")}
              number={state.number}
              country={state.country}
              required={true}
              onChangeNumber={(value) =>
                handlePhoneChange(state.country, value)
              }
              onChangeCountry={(value) =>
                handlePhoneChange(value, state.number)
              }
              autoComplete={"new-password"}
              valid={true}
              alt={true}
            />
            {!isValidNumber(state.country, state.number) &&
              state.number !== "" && (
                <Required>
                  <Leyend>{translate("INVALID_NUMBER")}</Leyend>
                </Required>
              )}
            <Space />
            <Space />

            <Div margin="0 0 24px 0">
              <UniversalText className="title">
                {translate("BUSINESS_INFO")}
              </UniversalText>
            </Div>

            <BlueInputCustom
              icon="star"
              type="text"
              value={info.company}
              onChange={(e) => handleInfo("company", e.target.value)}
              title={translate("NAME_COMPANY")}
            />
            <Space />
            <BlueInputCustom
              icon="squares"
              type="text"
              value={info.typeBusiness}
              onChange={(e) => handleInfo("typeBusiness", e.target.value)}
              title={translate("TYPE_BUSINESS")}
            />
            <Space />
            <BlueInputCustom
              icon="location"
              type="text"
              value={info.location}
              onChange={(e) => handleInfo("location", e.target.value)}
              title={translate("CITY")}
            />
            <Space />
            <BlueInputCustom
              icon="bigHashtag"
              type="text"
              value={info.places}
              onChange={(e) => handleInfo("places", e.target.value)}
              title={translate("NUMBER_LOCATIONS")}
            />
            <Space />
            <BlueInputCustom
              icon="portfolio"
              type="text"
              value={info.businessPosition}
              onChange={(e) => handleInfo("businessPosition", e.target.value)}
              title={translate("POSITION")}
            />
          </InputContainer>
        </CardStyled>
        <SubmitContainer>
          <Submit text={translate("SEND_INFO")} />
        </SubmitContainer>
      </Box>
    </>
  );
};

export default Business;
