import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Lang } from "etiquette";
import {Container, LangContainer, LeftNavbarContainer} from 'components/Navbar/style';
import Logo from "components/Logo";
import useI18n from 'i18n/useI18n';
import quinceLogo from "resources/img/logo-quince.svg";
import { SurveyContext } from "providers/survey";
import VERSION_CODE from "resources/constants/version";

const Navbar = ({ inline }) => {
  const history = useHistory();
  const { survey, setSurvey , resetSurvey } = useContext(SurveyContext);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const {selectedLang, setSelectedLang , translate} = useI18n();

  useEffect(() => {
    setIsLoginVisible(!!survey?.internal_data?.cashier_name);
  }, [survey]);

  useEffect(()=>{
    selectedLang ? setSurvey('survey_config.lang', 'EN') : setSurvey('survey_config.lang', 'ES')
  },[selectedLang]);

  const handleLogout = () => {
    resetSurvey()
    localStorage.removeItem("cashieruser");
    history.push("login");
  };

  const reg = /[^/]+$/
  const preview = window.location.href.match(reg);
  let toggle = true

  if (preview !== null) {
    toggle = preview[0] === 'preview'
  }

  const noLogin = survey?.internal_data?.cashier_name === "";

  return (
    <Container 
      className={inline ? "row" : ""} 
      marginTop={noLogin ? "32px" : "0"}
      marginBottom={noLogin ? "48px" : "0"}
    >
      <LeftNavbarContainer>
        <span className='version'>{VERSION_CODE}</span>
        {
           !!survey?.internal_data?.table_n && !!survey?.internal_data?.ticket_n && (
                <>
                  <span className="survey-data">{`${translate("Table")}: ${survey.internal_data.table_n}`}</span>
                  <span className="survey-data">{`Ticket: ${survey.internal_data.ticket_n}`}</span>
                </>
            )
        }
      </LeftNavbarContainer>
      <div className="log">
        {(isLoginVisible && toggle) && <>
          <p onClick={handleLogout} style={{color : 'white'}}>
            {translate("LOGOUT")} 
          </p>
          <p  style={{color : 'white'}}>( {survey?.internal_data?.cashier_name} )</p>
          </>
        }
      </div>

      <div className="logo">
        <Logo src={quinceLogo} alt="Logo Quince" width={noLogin ? "200%" : "100%"} />
      </div>

      <div className="lang">
        <LangContainer>
          <Lang selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
        </LangContainer>
      </div>
    </Container>
  );
};

export default Navbar;
