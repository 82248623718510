import React, { useState, useEffect, useContext } from "react";

import filterByCategory from "../utils/filterByCategory";
import productsToTree from "../utils/productsToTree";
import { DataContext } from "providers/data";
import { SurveyContext } from "providers/survey";
import { PRODUCT_IMG_URL } from "resources/constants/urls";
import useI8n from "i18n/useI18n";

import Product from "./Product";
import ScrollToID, { horizontalScrollToID } from 'pages/Survey/utils/scrollToID';

const ProductList = ({ setTotal, setRated, propOnSurvey, categoryList, containerID }) => {
  const [tree, setTree] = useState([]);
  const [list, setList] = useState([]);
  const { translate, selectedLang } = useI8n();
  const { data } = useContext(DataContext);
  const { setSurvey } = useContext(SurveyContext);

  useEffect(() => {
    const food = data.categories[categoryList];
    const products = data.ticket.data;
    const itemsOfFood = filterByCategory(food, products);
    const productTree = productsToTree(itemsOfFood);
    setTree(productTree);
  }, []);

  useEffect(() => {
    setSurvey(propOnSurvey, tree);
    const newList = renderList(tree);
    setList(newList);
  }, [tree, selectedLang]);

  const rateItem = (category, index, rating) => {
    const newTree = { ...tree };
    newTree[category][index].rating = rating;

    setTree(newTree);
  };

  function generatImgSrc(productId) {
    const product = getProductItem(productId);

    return product
      ? PRODUCT_IMG_URL + product.img
      : PRODUCT_IMG_URL + "noimage.svg";
  }

  function translateProduct(p) {
    const product = getProductItem(p.id);
    if (!product) return p.name;

    return selectedLang ? product.MenuItemEnglish : product.MenuItemSpanish;
  }

  function getProductItem(productId) {
    const products = data.products;
    return products[productId] || false;
  }

  const renderList = (tree) => {
    const listOfProducts = [];
    let ratedCount = 0;
    let totalItems = 0;

    for (const property in tree) {
      tree[property].forEach((p, i) => {
        p.rating > 0 && ratedCount++;
        totalItems++;
        listOfProducts.push({
          src: generatImgSrc(p.id),
          alt: p.name,
          text: translateProduct(p),
          status:
            p.rating === 0
              ? translate("NOT_RATED")
              : `${translate("RATED_WITH")} ${p.rating} ${translate("STARS")}`,
          stars: p.rating,
          setStars: (value) => rateItem(property, i, value),
        });
      });
    }

    setRated(ratedCount);
    setTotal(totalItems);

    return listOfProducts;
  };

  const listLong = list.length;
  const lastItem = (propOnSurvey === 'internal_data.products') ? 'FoodTextAreaContainer' : 'DrinkTextArea';

  return (
    <>
      {list.map((p, i) => {
        const lastProduct = listLong-1 === i;
        return(
          <Product 
            id={propOnSurvey+"-"+i}
            key={propOnSurvey+"-"+i}
            next={()=> lastProduct ? ScrollToID(lastItem, 'center') : horizontalScrollToID(containerID, `${propOnSurvey}-${i+1}`)}
            {...p} 
          />
        )
      })}
    </>
  );
};

export default ProductList;
