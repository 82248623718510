import styled from "styled-components";

export const Container = styled.div`
width: 80%;
margin: 20px auto 0;
display: grid;
justify-content: space-evenly;
grid-template-rows: auto 1fr;
grid-template-columns: 1fr 1fr 1fr;
grid-template-areas:
  "log - lang"
  "logo logo logo";
.log {
  grid-area: log;
  align-self: center;
  justify-self: flex-start;
  cursor: pointer;
}
.logo {
  grid-area: logo;
  align-self: center;
  justify-self: center;
  width: 200px;
  margin-top: ${({marginTop})=>(marginTop || "0")};
  margin-bottom: ${({marginBottom})=>(marginBottom || "0")};
}
.lang {
  grid-area: lang;
  justify-self: flex-end;
  align-self: center;
  padding-bottom: 30px;
  justify-content: flex-end;
}
@media (max-width: 678px) {
  width: 90%;
}
@media (max-width: 470px) {
  grid-template-columns: 1fr auto 1fr;
}

&.row {
  grid-template-rows: 1fr;
  grid-template-areas:  "log logo lang";
  margin: 5px auto 3px;
  @media (max-width: 345px) {
    grid-template-columns: 50px 1fr 1fr;
  }
  @media (max-width: 305px) {
    grid-template-columns: 43px 1fr 1fr;
  }
  @media (max-width: 290px) {
    grid-template-columns: 20px 1fr 1fr;
  }
  .logo {
    width: 200px;
    @media (max-width: 335px) {
      width: 180px;
    }
  }
  .log{
    @media (max-width: 345px) {
      font-size: 14px;
    }
    @media (max-width: 305px) {
      justify-self: flex-end;
      font-size: 12px;
    }
  }
}
.version {
  font-size: 10px;
  color: white;
  margin: 0;
}
  
  .survey-data{
    font-size: 11px;
    color: white;
    margin: 0;
    text-align: left;
    padding-left: 0.5rem;
    font-weight: bold;
  }
`;

export const LangContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const LeftNavbarContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5px;
    left: 5px;
    gap: 5px;
`