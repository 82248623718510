import React from 'react';
import Ok from 'resources/img/ok-icon.svg';
import styled from 'styled-components';
import {CustomText} from 'etiquette';
import { useHistory } from "react-router-dom";

const Container = styled.div`
    display: block;
    text-align:center;
    margin: 0 auto;
    width: 90%;
`;
const Img = styled.img`
    width: 80px;
    height: 80px;
    margin: 0 auto 25px;
    cursor: pointer;

    @media (max-width: 375px) {
        width: 60px;
        height: 60px;
        margin: 0 auto 10px;
      }
`;
const Title = styled(CustomText)`
    text-align: center;
    font-size: min(36px, 8vw);
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 40px;
`;
const Subtitle = styled(CustomText)`
    width: 75%;
    margin: auto;
    text-align: center;
    font-weight: 400;
    font-size: min(26px, 5vw);
    line-height: 1.2;
`;
const Info = styled(CustomText)`
    text-align: center;
    font-size: max(16px, 2vw);
    line-height: 1.2;
`;
const PlusText = styled(Subtitle)`
      margin-top: 20px;
`;

const Success = ({title, info, subtitle, plus}) => {
    const history = useHistory();
    
    const handle = () => history.push("/");
    
    return(
        <Container>
            <Img src={Ok} onClick={handle}/>
            <Title>{title}</Title>
            <Info>{info}</Info>
            <Subtitle>{subtitle}</Subtitle>
            <PlusText>{plus}</PlusText>
        </Container> 
    );
}
 
export default Success;
