import React, { useContext } from 'react'
import { Input } from "etiquette";
import { SurveyContext } from "providers/survey";
import useI8n from "i18n/useI18n";

const Table = () => {
  const { translate } = useI8n();
  const { survey } = useContext(SurveyContext );

  return (
    <Input
      title={translate("NUM_TABLE")}
      type="text"
      value={survey?.internal_data?.table_n}
      style={{pointerEvents: 'none', input: {backgroundColor: '#f7f7f7'}}}
    />
  );
};

export default Table;
