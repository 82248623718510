import React, { useContext, useEffect, useRef } from "react";
import { Transition, CircularCheckboxWhite } from 'etiquette';
import { InputContainer } from "./style";
import { Space } from "pages/Survey/components/Space";
import { RadioContainer } from "pages/Survey/containers/RadioContainer";
import { SliceDown } from 'pages/Survey/components/SliceDown';
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import { BusinessContext } from "providers/business";
import { ValidatorContext } from "providers/validator";
import { getPathValue } from "utils/objectUtils";
import { Required } from "./style";
import { Leyend } from "./style";
import isValidEmail from "pages/Survey/utils/validEmail";

import BlueInputMail from "components/BlueInputMail";
import BlueInputCustom from "components/BlueInputCustom";
import EmailLeyends from "pages/Survey/components/EmailLeyends";
import { findNumber } from "utils/stringUtils";
import ScrollToID from 'pages/Survey/utils/scrollToID';

const QuinceCrowd = () => {
  const { survey, setSurvey } = useContext(SurveyContext);
  const { translate } = useI8n();
  const { registerAll, errors } = useContext(ValidatorContext);
  const nameRef = useRef();
  const emailRef = useRef();

  useEffect(
    () => {
      const toRegister = [];
      toRegister.push({
        key: "vip_contact_name",
        opts: {
          validate: (survey) => {
            if (getPathValue(survey, "contact_cx") === true) return true;
            if (
              getPathValue(survey, "vip_cx_name") === "" &&
              getPathValue(survey, "vip_cx_lastName") === "" &&
              getPathValue(survey, "vip_cx_email") === ""
            )
              return true;
            return getPathValue(survey, "vip_cx_name") !== "";
          },
        },
        ref: nameRef.current,
      });
      toRegister.push({
        key: "vip_contact_email",
        opts: {
          validate: (survey) => {
            if (getPathValue(survey, "contact_cx") === true) return true;
            if (
              getPathValue(survey, "vip_cx_name") === "" &&
              getPathValue(survey, "vip_cx_lastName") === "" &&
              getPathValue(survey, "vip_cx_email") === ""
            )
              return true;
            return (
              getPathValue(survey, "vip_cx_email") !== "" &&
              isValidEmail(getPathValue(survey, "vip_cx_email"))
            );
          },
        },
        ref: emailRef.current,
      });

      registerAll(toRegister);
    },
    [nameRef.current, emailRef.current],
    survey.vip_cx_email
  );

  const { setBusiness } = useContext(BusinessContext);

  const handleContact = (businessProp, value) =>
    setBusiness(businessProp, value);
    ScrollToID("scroll-13");

  const handleSurveyText = (surveyProp, value, businessProp) => {
    if (!findNumber(value)) {
      setSurvey(surveyProp, value);
      setBusiness(businessProp, value);
    }
  };

  const handleSurveyMail = (surveyProp, value, businessProp) => {
    setSurvey(surveyProp, value);
    setBusiness(businessProp, value);
  };

  return (
    <>
      <Transition display="display" fadeout={true}>
        <SliceDown>
          <RadioContainer>
            <CircularCheckboxWhite
              name="quince-join"
              text={translate("Mr")}
              label="Mr"
              onClick={() => handleContact("gender", "Mr")}
              margin="0 40px 0 0"
            />
            <CircularCheckboxWhite
              name="quince-join"
              text={translate("Ms")}
              label="Ms"
              onClick={() => handleContact("gender", "Ms")}
              margin="0 40px 0 0"
            />
            <CircularCheckboxWhite
              name="quince-join"
              text={translate("NotD")}
              label="NotD"
              onClick={() => handleContact("gender", "Not Disclosed")}
              margin="0 40px 0 0"
            />
          </RadioContainer>
          <InputContainer>
            <BlueInputCustom
              icon="customer"
              type="text"
              value={survey.vip_cx_name}
              onChange={(e) =>
                handleSurveyText("vip_cx_name", e.target.value, "name")
              }
              title={translate("FIRST_NAME")}
              ref={nameRef}
              id="qcName"
            />
            {errors.includes("vip_contact_name") && survey?.vip_cx_name === "" && (
              <Required>
                <Leyend>{translate("REQUIRED")}</Leyend>
                <br />
              </Required>
            )}
            <Space />
            <BlueInputCustom
              icon="customer"
              type="text"
              value={survey.vip_cx_lastName}
              onChange={(e) =>
                handleSurveyText("vip_cx_lastName", e.target.value, "lastName")
              }
              title={translate("LAST_NAME")}
            />
            <Space />
            <BlueInputMail
              textLabel={translate("EMAIL")}
              value={survey.vip_cx_email}
              onChange={(e) =>
                handleSurveyMail("vip_cx_email", e.target.value, "mail")
              }
              ref={emailRef}
            />
            <div id='scroll-13'></div>
            {errors.includes("vip_contact_email") &&
              survey?.vip_cx_email === "" && (
                <Required>
                  <Leyend>{translate("REQUIRED")}</Leyend>
                </Required>
              )}
            {survey?.vip_cx_email !== "" &&
              !isValidEmail(survey?.vip_cx_email) && (
                <Required>
                  <Leyend>
                    <EmailLeyends string={survey?.vip_cx_email} />
                  </Leyend>
                </Required>
              )}
          </InputContainer>
        </SliceDown>
      </Transition>
    </>
  );
};

export default QuinceCrowd;
