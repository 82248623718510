import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import usePeople from "../hooks/usePeople";
import { DropdownInput } from "etiquette";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const DropArea = styled.div`
  margin-bottom: 30px;
`;

const Operator = () => {
  const { survey } = useContext(SurveyContext);
  const { translate } = useI8n();
  const [handleOptions, options, selected, setSelected] = usePeople(
    "operators",
    "internal_data.operator"
  );

  useEffect(() => {
    if (!selected && options && options.length) {
      setSelected(options[0].id);
    }
  }, [options]);

  return (
    <>
      {!!options && options.length > 0 && (
        <DropArea>
          <DropdownInput
            text={translate("OPERATOR")}
            options={options}
            onChange={handleOptions}
            value={selected}
            initialValue={survey?.internal_data?.operator?.id}
          />
        </DropArea>
      )}
    </>
  );
};

export default Operator;
