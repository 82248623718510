import React, { useContext } from "react";
import CardStyled from "components/Card.jsx";
import { SurveyStars, StarBox } from "etiquette";
import { SurveyContext } from "providers/survey";
import useI8n from "i18n/useI18n";

const Overall = ({ handleProgress }) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);

  const handleSurvey = (value) => {
    const sameValue = value === survey?.overall_experience;
    const move = value !== survey?.overall_experience;
    
    setSurvey("overall_experience", sameValue ? 0 : value);

    handleProgress("overall", 0, sameValue, move)
  }

  return (
    <CardStyled>
      <StarBox
        text={translate("OVERALL")}
        titleAlign='center'
      >
        <SurveyStars
          value={survey?.overall_experience}
          setValue={handleSurvey}
        />
      </StarBox>
    </CardStyled>
  );
};

export default Overall;
