import React , {useContext} from 'react';
import CardStyled from 'components/Card.jsx';
import { TagBox, TagButton } from 'etiquette';
import { SurveyContext } from "providers/survey";
import useI8n from "i18n/useI18n";

const Perfect = ({ handleProgress }) => {

  const { translate } = useI8n();
  const { setSurvey } = useContext(SurveyContext);

  const handleBox = (value) => {
    setSurvey('manager_inquire_perfection',value)
    handleProgress("perfect", 1, false, true);
  }

  return (
    <CardStyled>
      <TagBox
        text={translate("WHICH_PERFECT")}
        subtext=''
        titleAlign='center'
      >
        <TagButton
          text={translate("PERFECT_MANAGER")}
          onClick={() => handleBox("managerCaptainInquired")}
          name="team"
          label="team1"
          margin='0 16px 20px 0'
        />
        <TagButton
          text={translate("SERVER")}
          onClick={() => handleBox("serverInquired")}
          name="team"
          label="team3"
          margin='0 16px 20px 0'
        />
        <TagButton
          text={translate("OTHER")}
          onClick={() => handleBox("someoneInquired")}
          name="team"
          label="team4"
          margin='0 16px 20px 0'
        />
        <TagButton
          text={translate("NOBODY")}
          onClick={() => handleBox("nobodyInquired")}
          name="team"
          label="team5"
          margin='0 16px 20px 0'
        />
        <TagButton
          text={translate("NO_REMEMBER")}
          onClick={() => handleBox("dontRemember")}
          name="team"
          label="team6"
          margin='0 16px 20px 0'
        />
      </TagBox>
    </CardStyled>
  );
}; 

export default Perfect;
