import React from "react";
import { Hr } from "etiquette";

const Section = ({ children, id }) => (
  <div id={id}>
    {children}
    <Hr />
  </div>
);


export const LastSection = ({ children, id }) => (
  <div id={id}>
    {children}
  </div>
);

export default Section;
