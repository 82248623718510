import React, { useContext } from "react";
import styled from "styled-components";
import usePeople from "../hooks/usePeople";
import { DropdownInput } from "etiquette";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const DropArea = styled.div`
  margin-bottom: 20px;
`;

const Waiter = () => {
  const { survey } = useContext(SurveyContext);
  const { translate } = useI8n();
  const [handleOptions, options, selected] = usePeople(
    "waiters",
    "internal_data.waiter",
    false
    );


  return (
    <div>
      {!!options && options.length > 0 && (
        <DropArea>
          <DropdownInput
            text={translate("WAITER")}
            options={options}
            value={selected}
            onChange={handleOptions}
            initialValue={survey?.internal_data?.waiter?.id}
          />
        </DropArea>
      )}
    </div>
  );
};

export default Waiter;
