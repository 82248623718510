import React, { useContext, useEffect } from 'react';
import { TagBox, TagButton, BlueTextArea, Transition } from 'etiquette';
import { TextArea } from 'pages/Survey/containers/FoodDrinks';
import useI8n from 'i18n/useI18n';
import { SurveyContext } from 'providers/survey';
import { Required, Leyend } from '../Style';

const Reason = ({reasonTextRef}) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);

  useEffect(()=>{
    setSurvey('cx_contact_reason', 'toComplement');
  },[])

  return (
    <>
      <TagBox text={translate('WICH_REASON')} titleAlign="center">
        <TagButton
          text={translate('COMPLEMENT_US')}
          name="whichReason"
          label="whichReason-complement"
          onClick={() => setSurvey('cx_contact_reason', 'toComplement')}
          margin="0 16px 20px 0"
          checked={survey?.cx_contact_reason === 'toComplement'}
        />
        <TagButton
          text={translate('HAD_PROBLEM')}
          name="whichReason"
          label="whichReason-problem"
          onClick={() => setSurvey('cx_contact_reason', 'hadProblem')}
          margin="0 16px 20px 0"
          checked={survey?.cx_contact_reason === 'hadProblem'}
        />
        <TagButton
          text={translate('OTHER_REASON')}
          name="whichReason"
          label="whichReason-other"
          onClick={() => setSurvey('cx_contact_reason', 'otherReason')}
          margin="0 16px 20px 0"
          checked={survey?.cx_contact_reason === 'otherReason'}
        />
      </TagBox>
      <Transition display={survey?.cx_contact_reason === 'otherReason' ? 'flex' : 'none'}>
        <TagBox text="">
          <TextArea>
            <BlueTextArea
              icon="comment"
              value={survey?.contact_other_reason_details}
              onChange={(e) => setSurvey('contact_other_reason_details', e.target.value)}
              maxlength="200"
              cols="40"
              rows="3"
              title={translate('WHAT_REASON')}
              ref={reasonTextRef}
            />
          </TextArea>
          {survey?.cx_contact_reason === 'otherReason' && survey?.contact_other_reason_details==='' && (
            <Required>
              <Leyend>{translate('REQUIRED')}</Leyend>
            </Required>
          )}
        </TagBox>
      </Transition>
    </>
  );
};

export default Reason;
