
export const ProgressBar = (progress) => {
  if (progress == 0 ) return "PROGRESS_0"
  if (progress > 0 && progress <= 20 ) return "PROGRESS_20"
  if (progress > 20 && progress <= 30 ) return "PROGRESS_30"
  if (progress > 30 && progress <= 40 ) return "PROGRESS_40"
  if (progress > 40 && progress <= 50 ) return "PROGRESS_50"
  if (progress > 50 && progress <= 60 ) return "PROGRESS_60"
  if (progress > 60 && progress <= 70 ) return "PROGRESS_70"
  if (progress > 70 && progress <= 80 ) return "PROGRESS_80"
  if (progress > 80 && progress < 100 ) return "PROGRESS_90"
  if (progress == 100 ) return "PROGRESS_100"
}

export default ProgressBar;
