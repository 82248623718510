import React, { useState } from "react";
import { Info } from "etiquette";
import {InfoContainer } from "./style";
import { TagBox, TagButton } from "etiquette";
import CardStyled from "components/Card.jsx";
import Form from "./Form";
import useI8n from "i18n/useI18n";

const Contact = () => {
  const { translate } = useI8n();
  const [isVisible, setIsVisible] = useState(false);


  return (
    <>
      <CardStyled>
        <TagBox text={translate("TO_JOIN")} titleAlign="center">
          <TagButton
            text={translate("YES")}
            onClick={() => setIsVisible(true)}
            name="join"
            label="join-yes"
          />
          <TagButton
            text={translate("NO")}
            onClick={() => setIsVisible(false)}
            name="join"
            label="join-no"
          />
        </TagBox>
        <InfoContainer>
          <Info>{translate("NOT_SPAM")}</Info>
          {isVisible && <Form fadeout={false} />}
        </InfoContainer>
      </CardStyled>
    </>
  );
};
export default Contact;
