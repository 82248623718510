
import { createContext, useState, useEffect } from 'react';

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [business, setDataBusiness] = useState({
    gender: '',
    name: '',
    lastName: '',
    mail: '',
    phone: '',
  })

  useEffect(() => {
    console.info('business', business);
  }, [business]);

  const setBusiness = (key, value) => {
    console.info(key, value);
    const data = {...business}
    data[key] = value;
    setDataBusiness(data)
  }

  function resetBusiness() {
    setDataBusiness({
      gender: '',
      name: '',
      lastName: '',
      mail: '',
      phone: '',
    })
  }

  return <BusinessContext.Provider value={{ business, setBusiness, resetBusiness }}>{children}</BusinessContext.Provider>
}
