import React from "react";
import { PasswordInput } from "etiquette";
import useI8n from "i18n/useI18n";

const Password = ({ value, onChange }) => {
  const { translate } = useI8n();

  return (
    <PasswordInput
      title={translate("PASSWORD")}
      name="Password"
      value={value}
      onChange={onChange}
      style={{ marginBottom: "40px" }}
    />
  );
};

export default Password;
