import styled from 'styled-components';
import { 
  CustomText,
} from "etiquette";

export const Box = styled.form`
    border-radius: 24px 24px 0px 0px;
    background-color: #FBFBFB;
    color: #0F191B;
    height: calc(100% - ${({ height })=> ( height || '100px' )});
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
`;

export const Div = styled.div`
  width: 100%;
  height: auto;
  margin: ${({margin})=>(margin||'0')};
  p {
    text-align: center;
  }
`;

export const UniversalText = styled(CustomText)`
  &.title {
    font-size: 20px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: start;
`;

export const CancelContainer = styled.div`
  width: 100px;
  position: relative;
  top: -84px;
  display: flex;
  p {
    color: white;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const SubmitContainer = styled.div`
  width: 100%;
  height: 72px;
  position: relative;
  display: flex;
  background-color: #FFF;
`;