import { useContext } from 'react'
import { SurveyContext } from "providers/survey";
import { DataContext } from 'providers/data';
import { useHistory } from "react-router-dom";

const useLogin = () => {
    const history = useHistory();
    const { survey } = useContext(SurveyContext)
    const { data } = useContext(DataContext)

    const doLogin = () => {
        ((!survey) || (!data?.people?.hostess)) &&  history.push("login")
    }

    return doLogin;
}

export default useLogin;