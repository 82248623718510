import { useContext } from "react";
import {
  TagButton,
  Transition,
  TagBoxVertical,
  Title,
} from "etiquette";
import {
  communication,
  friendlyTags,
  smilingTags,
} from "./hosting_attributes_rating";
import {
  SliceDown,
  HorizontalScroll,
  TitleContainer,
} from "pages/Survey/components/SliceDown";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import ScrollToID, { horizontalScrollToID } from 'pages/Survey/utils/scrollToID';

const Hostess = ({ display = "none", fadeout }) => {
  const { translate } = useI8n();
  const { survey, setSurvey } = useContext(SurveyContext);

  const setTag = (section, baseObject, attribute) => {
    const newObject = { ...baseObject };
    newObject[attribute] = true;
    setSurvey(`hosting_attributes_rating.${section}`, newObject);
  };

  const setSmilingTags = (attribute) => { 
    setTag("smilingTags", smilingTags, attribute)
    horizontalScrollToID("hostess_horizontal","hostess-demeanor")
  };
  const setFriendlyTags = (attribute) => { 
    setTag("friendlyTags", friendlyTags, attribute)
    horizontalScrollToID("hostess_horizontal","hostess-communication")
  };

  const setComunication = (attribute) => {
    setTag("communication", communication, attribute);
    ScrollToID("scroll-7");
  };

  const currentId = survey?.hostess_selected?.idHostess;
  const currentName = survey?.hostess_selected?.nameHostess;
  
  const hostSelected = currentId !== -1 && currentId !== 999

  const hostessName = hostSelected ? currentName : ''

  return (
    <Transition
      display={display}
      fadeout={fadeout}
    >
      <SliceDown>
        <TitleContainer>
          <Title>
            {translate('THAT_RATING')} <b><i style={{textTransform: 'capitalize'}}> {hostessName}</i></b> {translate('THAT_RATING_COMPLEMENT')}
          </Title>
        </TitleContainer>
        <HorizontalScroll items={3} id="hostess_horizontal">
          <TagBoxVertical text={translate('ATTITUDE')} id="hostess-attitude">
            <TagButton
              text={translate("ALWAYS_SMILING")}
              onClick={() => setSmilingTags("smiling")}
              name="attitude"
              label="HAsimiling"
              margin='8px 30px'
            />
            <TagButton
              text={translate("SMILING_ENOUGH")}
              onClick={() => setSmilingTags("notSmiling")}
              name="attitude"
              label="HAnotSmiling"
              margin='8px 30px'
            />
            <TagButton
              text={translate("FROWNING")}
              onClick={() => setSmilingTags("frowning")}
              name="attitude"
              label="HAfrowning"
              margin='8px 30px'
            />
          </TagBoxVertical>
          <TagBoxVertical text={translate("DEMEANOR")} id="hostess-demeanor">
            <TagButton
              text={translate("VERY_FRIENDLY")}
              onClick={() => setFriendlyTags("superFriendly")}
              name="demeanor"
              label="HDsuperFriendly"
              margin='8px 30px'
            />
            <TagButton
              text={translate("Ok")}
              onClick={() => setFriendlyTags("friendly")}
              name="demeanor"
              label="HDfriendly"
              margin='8px 30px'
            />
            <TagButton
              text={translate("UNFRIENDLY")}
              onClick={() => setFriendlyTags("unfriendly")}
              name="demeanor"
              label="HDunfriendly"
              margin='8px 30px'
            />
          </TagBoxVertical>
          <TagBoxVertical text={translate("COMMUNICATION")} id="hostess-communication">
            <TagButton
              text={translate("GREAT")}
              onClick={() => setComunication("great")}
              name="communication"
              label="HCgreat"
              margin='8px 30px'
            />
            <TagButton
              text={translate("OK")}
              onClick={() => setComunication("ok")}
              name="communication"
              label="HCok"
              margin='8px 30px'
            />
            <TagButton
              text={translate("POOR")}
              onClick={() => setComunication("poor")}
              name="communication"
              label="HCpoor"
              margin='8px 30px'
            />
          </TagBoxVertical>
        </HorizontalScroll>
      </SliceDown>
    </Transition>
  );
};

export default Hostess;
