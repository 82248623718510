const filledSurvey = {
    "survey_config": {
        "delimiter": "",
        "status": "received",
        "flow": "internal"
    },
    "internal_data": {
        "cuisine_rated": false,
        "mixology_rated": false,
        "products": {},
        "products_details": "",
        "foodOverallStars": 0,
        "mixology": {},
        "mixology_details": "",
        "mixologyOverallStars": 0
    },
    "overall_experience": 0,
    "hostess_selected": {
        "idHostess": 0,
        "nameHostess": ""
    },
    "hostess_dontRemember_clicked": false,
    "hosting_basic_rating": {
        "stars": 0,
        "no_opinion": false
    },
    "hosting_attributes_rating": {
        "smilingTags": {
            "smiling": false,
            "notSmiling": false,
            "frowning": false
        },
        "friendlyTags": {
            "superFriendly": false,
            "friendly": false,
            "unfriendly": false
        },
        "courtesyCtl": {
            "offered": true,
            "notOffered": false
        },
        "communication": {
            "great": false,
            "ok": false,
            "poor": false
        },
        "reservations": {
            "helpful": false,
            "noProblem": false,
            "notHelpful": false,
            "noOpinion": false
        }
    },
    "cx_wait_time": "",
    "cx_check_wait_time": "",
    "attractive_altern": "",
    "manager_selected": {
        "idManager": 0,
        "nameManager": ""
    },
    "manager_dontRemember_clicked": false,
    "manager_basic_rating": {
        "stars": 0,
        "no_opinion": false
    },
    "manager_attributes_rating": {
        "smilingTags": {
            "smiling": false,
            "notSmiling": false,
            "frowning": false
        },
        "friendlyTags": {
            "superFriendly": false,
            "friendly": false,
            "unfriendly": false
        },
        "helpfulness": {
            "helpful": false,
            "noHelpNeeded": false,
            "notHelpful": false
        }
    },
    "manager_inquire_perfection": "",
    "manager_checked_experience": "",
    "chef_met": "",
    "server_basic_rating": {
        "stars": 0,
        "no_opinion": false
    },
    "server_attributes_rating": {
        "smilingTags": {
            "smiling": false,
            "notSmiling": false,
            "frowning": false
        },
        "friendlyTags": {
            "superFriendly": false,
            "friendly": false,
            "unfriendly": false
        },
        "speedTags": {
            "fast": false,
            "ok": false,
            "slow": false
        },
        "interruptionsTags": {
            "adequate": false,
            "tooMany": false
        },
        "menuQuestions": {
            "perfectly": false,
            "ok": false,
            "notGood": false
        },
        "foodDelivery": {
            "perfectly": false,
            "adequate": false,
            "slow": false,
            "noOpinion": false
        },
        "foodTemperature": {
            "perfectly": false,
            "adequate": false,
            "incorrect": false,
            "noOpinion": false
        }
    },
    "drink_service": "",
    "ambiance_rating": 0,
    "music_rating": 0,
    "music_below_threshold_reason": "",
    "establishment_tags": "",
    "socialMedia_selected": "",
    "answered_more_questions": false,
    "vip_cx_name": "",
    "vip_cx_lastName": "",
    "vip_cx_email": "",
    "contact_cx": false,
    "cx_contact_reason": "",
    "contact_other_reason_details": "",
    "contact_via": {
        "phone": false,
        "email": false
    },
    "cx_contact_phone": "",
    "cx_contact_email": "",
    "cx_name": "",
    "cx_lastName": ""
}

export default filledSurvey;