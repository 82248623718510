import React, { useContext } from "react";
import {
  smilingTags,
  friendlyTags,
  speedTags,
  interruptionsTags,
  menuQuestions,
  foodDelivery,
  foodTemperature,
} from "./server_attributes.js";
import { 
  StarBox, 
  SurveyStars 
} from "etiquette";
import TagIds from './tagsArray';
import CardStyled from "components/Card";
import Server from "./Server";
import useVisible from "pages/Survey/hooks/useVisible";
import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";
import { ResetTags } from "pages/Survey/utils/resetTags";
import ScrollView from 'pages/Survey/utils/scrollIntoViewport';


const ServerSurvey = ({ handleProgress }) => {
  const { survey, setSurvey, setSurveyBulk } = useContext(SurveyContext);
  const [visible, handle, transition] = useVisible();
  const { translate } = useI8n();

  const handleStars = (value) => {
    const sameValue = value === survey?.server_basic_rating?.stars;
    const zeroFive = sameValue || value === 5;
    const move = value === 5 && !sameValue;

    if (zeroFive) {
      handle(0);
      setSurveyBulk({
        "server_attributes_rating.smilingTags": { ...smilingTags },
        "server_attributes_rating.friendlyTags": { ...friendlyTags },
        "server_attributes_rating.speedTags": { ...speedTags },
        "server_attributes_rating.interruptionsTags": { ...interruptionsTags },
        "server_attributes_rating.menuQuestions": { ...menuQuestions },
        "server_attributes_rating.foodDelivery": { ...foodDelivery },
        "server_attributes_rating.foodTemperature": { ...foodTemperature },
        drink_service: "",
        server_basic_rating: {
          stars: sameValue ? 0 : 5,
          no_opinion: false,
        },
      });
      ResetTags(TagIds);
    } else {
      ScrollView("progress", "scroll-2")
      handle(value);
      setSurvey("server_basic_rating", {
        stars: value,
        no_opinion: false,
      });

    }
    const serverID = 2;
    const identifier = "service"
    handleProgress(identifier, serverID, sameValue, move);
  };

  return (
    <>
      <CardStyled>
        <StarBox 
          text={translate("RATE_TABLE")}
          titleAlign='center'
        >
          <SurveyStars
            value={survey?.server_basic_rating?.stars}
            setValue={handleStars}
            id="server"
          />
        </StarBox>
        <Server display={visible} fadeout={transition} />
      </CardStyled>
    </>
  );
};

export default ServerSurvey;
