
const ScrollView = (containerId, itemId, verticalALign='start', horizontalAlign="center") => {
  const container = document.getElementById(containerId);
  const element = document.getElementById(itemId);
  if (container && element) {
    element.scrollIntoView({block: verticalALign, behavior: "smooth", inline: horizontalAlign });
  } 
};

export default ScrollView;
