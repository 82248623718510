import React from "react";
import useI8n from "i18n/useI18n";
import { Box, Container } from "./styles";
import Success from "pages/Gratefulness/components/Success";
import DotellUs from './DotellUs';

const LearnMore = ({ name = "", onClick }) => {
  const { translate } = useI8n();
  return (
    <Box>
      <Container>
        <Success
          title={`${translate("THANK_YOUR_TIME")} ${name}!`}
          info={translate("ON_TOUCH")}
        />
        <DotellUs onClick={onClick} />
      </Container>
    </Box>
  );
};

export default LearnMore;