import React, { useState , useContext} from "react";
import {
  SubtitleArea,
  InfoArea,
  ColumnsArea,
  RateContainer,
  TextArea,
} from "pages/Survey/containers/FoodDrinks";
import {
  Transition,
  Subtitle,
  Info,
  RateItemContainer,
  Hr,
  BlueTextArea,
} from "etiquette";
import { SliceDown } from "pages/Survey/components/SliceDown";
import ProductList from "pages/Survey/components/ProductList";

import useI8n from "i18n/useI18n";
import { SurveyContext } from "providers/survey";

const Item = ({ display = "none", fadeout}) => {
  const { translate } = useI8n();
  const [rated, setRated] = useState(0);
  const [total, setTotal] = useState(0);
  const { survey, setSurvey } = useContext(SurveyContext);

  return (
    <Transition display={display} fadeout={fadeout}>
      <SliceDown>
        <SubtitleArea>
          <Subtitle>{translate("FOOD_SUBTITLE")}</Subtitle>
        </SubtitleArea>
        <InfoArea>
          <Info>{translate("GASTRONOMIC_RATE_ITEMS_FOOD")}</Info>
        </InfoArea>
        <ColumnsArea>
          <p>{`${rated} ${translate("FOOD_ITEMS1")} ${total} ${translate(
            "FOOD_ITEMS2"
          )}`}</p>
        </ColumnsArea>
        <RateContainer>
          <RateItemContainer id="food_horizontal">
            <ProductList
              setRated={setRated}
              setTotal={setTotal}
              propOnSurvey="internal_data.products"
              categoryList="food"
              containerID="food_horizontal"
            />
          </RateItemContainer>
        </RateContainer>
        <Hr />
        <div id="FoodTextAreaContainer">
          <TextArea>
            <BlueTextArea
              icon="comment"
              id="FoodTextArea"
              name="Text area input"
              value={survey?.internal_data?.products_details}
              onChange={(e) =>
                setSurvey("internal_data.products_details", e.target.value)
              }
              maxlength="200"
              cols="40"
              rows="3"
              title={translate("TEXT_AREA_TITLE_FOOD")}
            />
          </TextArea>
        </div>
      </SliceDown>
    </Transition>
  );
};

export default Item;
